import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import SubjectType from './interfaces/SubjectType';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';

export type SubjectTypesAction =
  | Action<
    'SubjectTypes.ReceiveEntities',
    {
      subjectTypes: SubjectType[],
    }
  >
  | Action<
    'SubjectTypes.ReceiveEntity',
    {
      subjectType: SubjectType,
    }
  >
  | Action<
    'SubjectTypes.ModifyEntity',
    {
      subjectType: SubjectType,
      id: string,
    }
  >
  | Action<
    'SubjectTypes.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<'SubjectTypes.OpenEntityModal', {
    subjectType?: SubjectType,
  }>
  | Action<'SubjectTypes.CloseEntityModal'>
  | Action<'SubjectTypes.StartLoading'>
  | Action<'SubjectTypes.FinishLoading'>;

export const subjectTypesActions = {
  fetchSubjectTypes(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectTypesActions.startLoading());
      return request<SubjectType[]>('/subject-types')
        .then((subjectTypes) => {
          dispatch(subjectTypesActions.receiveEntities(subjectTypes));
        })
        .finally(() => {
          dispatch(subjectTypesActions.finishLoading());
        });
    };
  },
  postSubjectType(subjectType: SubjectType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectTypesActions.startLoading());
      return request<SubjectType>('/subject-types', {
        method: Method.POST,
        body: subjectType,
      })
        .then((receivedSubjectType) => {
          dispatch(subjectTypesActions.receiveEntity(receivedSubjectType));
        })
        .finally(() => {
          dispatch(subjectTypesActions.finishLoading());
        });
    };
  },
  editSubjectType(id: string, subjectType: SubjectType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectTypesActions.startLoading());
      return request<SubjectType>(`/subject-types/${id}`, {
        method: Method.PUT,
        body: subjectType,
      })
        .then((receivedSubjectType) => {
          dispatch(subjectTypesActions.modifyEntity(receivedSubjectType._id, receivedSubjectType));
        })
        .finally(() => {
          dispatch(subjectTypesActions.finishLoading());
        });
    };
  },
  deleteSubjectType(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectTypesActions.startLoading());
      return request<SubjectType>(`/subject-types/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(subjectTypesActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(subjectTypesActions.finishLoading());
        });
    };
  },
  removeEntity(id: string): SubjectTypesAction {
    return {
      type: 'SubjectTypes.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, subjectType: SubjectType): SubjectTypesAction {
    return {
      type: 'SubjectTypes.ModifyEntity',
      payload: {
        subjectType,
        id,
      },
    };
  },
  receiveEntity(subjectType: SubjectType): SubjectTypesAction {
    return {
      type: 'SubjectTypes.ReceiveEntity',
      payload: {
        subjectType,
      },
    };
  },
  receiveEntities(subjectTypes: SubjectType[]): SubjectTypesAction {
    return {
      type: 'SubjectTypes.ReceiveEntities',
      payload: {
        subjectTypes,
      },
    };
  },
  openEntityModal(subjectType?: SubjectType): SubjectTypesAction {
    return {
      type: 'SubjectTypes.OpenEntityModal',
      payload: {
        subjectType,
      },
    };
  },
  closeEntityModal(): SubjectTypesAction {
    return {
      type: 'SubjectTypes.CloseEntityModal',
      payload: undefined,
    };
  },
  startLoading(): SubjectTypesAction {
    return {
      type: 'SubjectTypes.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): SubjectTypesAction {
    return {
      type: 'SubjectTypes.FinishLoading',
      payload: undefined,
    };
  },
};
