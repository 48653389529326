import Action from '../../../shared/types/Action';
import FieldOfStudy from '../interfaces/FieldOfStudy';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { calculationsListActions } from '../../Calculations/actions/list';
import { thresholdsListActions } from '../../Thresholds/actions/list';

export type FieldsOfStudyModalAction =
  | Action<'FieldsOfStudy.Modal.Open', {
    fieldOfStudy?: FieldOfStudy,
  }>
  | Action<'FieldsOfStudy.Modal.Close'>;

export const fieldsOfStudyModalActions = {
  closeAndClear() {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(calculationsListActions.clear());
      dispatch(thresholdsListActions.clear());
      dispatch(fieldsOfStudyModalActions.close());
    };
  },
  open(fieldOfStudy?: FieldOfStudy): FieldsOfStudyModalAction {
    return {
      type: 'FieldsOfStudy.Modal.Open',
      payload: {
        fieldOfStudy,
      },
    };
  },
  close(): FieldsOfStudyModalAction {
    return {
      type: 'FieldsOfStudy.Modal.Close',
      payload: undefined,
    };
  },
};
