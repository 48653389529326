import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import React from 'react';
import CheckboxInput from '../../../shared/components/inputs/CheckboxInput';
import useSubjectModalManagement from '../hooks/useSubjectModalManagement';
import useSubjectsManagement from '../hooks/useSubjectsManagement';
import SelectInput from '../../../shared/components/inputs/SelectInput';
import entitiesToOptions from '../../../shared/utilities/entitiesToOptions';
import useSubjectTypesManagement from '../../SubjectTypes/hooks/useSubjectTypesManagement';

const SubjectModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, subject } = useSubjectModalManagement();
  const { postSubject, editSubject } = useSubjectsManagement();
  const { subjectTypes } = useSubjectTypesManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Przedmiot"
      isEditing={isEditing}
      onCreate={postSubject}
      onEdit={editSubject}
      id={subject?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      <TextInput
        name="iconName"
        type="text"
        label="Nazwa ikony"
      />
      <TextInput
        name="order"
        type="number"
        label="Kolejność"
      />
      <SelectInput
        name="subjectType"
        label="Typ przedmiotu"
        options={entitiesToOptions(subjectTypes)}
      />
      <CheckboxInput
        name="isRequired"
        label="Obowiązkowy"
      />
      <CheckboxInput
        name="isInitial"
        label="Wyświetl przedmiot przy pierwszej wizycie na aplikacji użytkownika"
      />
    </EntityModal>
  );
};

export default SubjectModal;
