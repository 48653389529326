import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import { useUniversitiesChartsData } from './useUniversitiesChartsData';
import ReportsChart from '../ReportsChart';

const UniversitiesCharts = () => {
  const { universitiesChartsData } = useUniversitiesChartsData();

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.header} variant="h5">Uniwersytety</Typography>
      <ReportsChart data={universitiesChartsData}/>
    </div>
  );
};

export default UniversitiesCharts;
