import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import { departmentsModalActions } from '../actions/modal';
import Department from '../interfaces/Department';
import { fieldsOfStudyListActions } from '../../FieldsOfStudy/actions/list';

function useDepartmentsModalManagement() {
  const dispatch = useDispatch();
  const {
    isOpened,
    entity,
    university,
  } = useSelector((state: AppState) => ({
    isOpened: state.departments.modal.isOpened,
    entity: state.departments.modal.entity,
    university: state.universities.editModal.entity,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      if (university) {
        dispatch(departmentsModalActions.open(university._id));
      }
    },
    [dispatch, university],
  );

  const handleEdit = useCallback(
    (department: Department) => (
      () => {
        if (university) {
          dispatch(departmentsModalActions.open(university._id, department));
          dispatch(fieldsOfStudyListActions.receiveEntities(department.fieldsOfStudy));
        }
      }
    ),
    [dispatch, university],
  );

  const handleClose = useCallback(
    () => {
      dispatch(departmentsModalActions.closeAndClear());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        ...entity,
      } : {
        name: '',
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    isEditing,
    initialValues,
    handleOpen,
    handleClose,
    handleEdit,
    university,
    department: entity,
  };
}

export default useDepartmentsModalManagement;
