import React from 'react';
import TextInput from '../../../../shared/components/inputs/TextInput';
import SelectInput from '../../../../shared/components/inputs/SelectInput';
import entitiesToOptions from '../../../../shared/utilities/entitiesToOptions';
import useUniversityTypesManagement from '../../../UniversityTypes/hooks/useUniversityTypesManagement';
import useCitiesManagement from '../../../Cities/hooks/useCitiesManagement';
import CheckboxInput from '../../../../shared/components/inputs/CheckboxInput';
import { useFormikContext } from 'formik';
import University from '../../interfaces/University';

const BasicForm = () => {
  const { universityTypes } = useUniversityTypesManagement();
  const { cities } = useCitiesManagement();

  const { values } = useFormikContext<University>();

  return (
    <>
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      <SelectInput
        name="type"
        options={entitiesToOptions(universityTypes)}
      />
      <SelectInput
        name="city"
        options={entitiesToOptions(cities)}
      />
      <CheckboxInput name="isPrivate" label="Uczelnia prywatna" />
      {
        values.isPrivate && (
          <>
            <TextInput
              name="description"
              type="text"
              label="Opis"
              multiline={true}
            />
            <TextInput
              name="logoUrl"
              type="text"
              label="URL logo"
            />
            <TextInput
              name="photoUrl"
              type="text"
              label="URL zdjęcia"
            />
          </>
        )
      }
    </>
  );
};

export default BasicForm;
