import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/PeopleAlt';

const items = [
  [
    {
      name : 'Uniwersytety',
      path: '/universities',
      Icon: InboxIcon,
    },
    {
      name : 'Typy uniwersytetów',
      path: '/university-types',
      Icon: MailIcon,
    },
    {
      name : 'Typy kierunków',
      path: '/field-of-study-types',
      Icon: InboxIcon,
    },
  ],
  [
    {
      name : 'Miasta',
      path: '/cities',
      Icon: InboxIcon,
    },
    {
      name : 'Województwa',
      path: '/voivodeships',
      Icon: MailIcon,
    },
  ],
  [
    {
      name : 'Przedmioty',
      path: '/subjects',
      Icon: InboxIcon,
    },
    {
      name : 'Typy przedmiotów',
      path: '/subject-types',
      Icon: MailIcon,
    },
  ],
  [
    {
      name : 'Użytkownicy',
      path: '/users',
      Icon: PeopleIcon,
    },
  ],
  [
    {
      name : 'Raporty',
      path: '/reports',
      Icon: InboxIcon,
    },
  ],
];

export default items;
