import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { citiesActions } from '../actions';
import { useCallback } from 'react';
import City from '../interfaces/City';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useCityModalManagement from './useCityModalManagement';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useCitiesManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { handleClose } = useCityModalManagement();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.cities.entities,
    isLoading: state.cities.isLoading,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const fetchCities = useCallback(
    () => {
      return dispatch(citiesActions.fetchCities());
    },
    [dispatch],
  );

  const postCity = useCallback(
    (city: City) => {
      return dispatch(citiesActions.postCity(city))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editCity = useCallback(
    (id: string, city: City) => {
      return dispatch(citiesActions.editCity(id, city))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const deleteCity = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(citiesActions.deleteCity(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchCities,
    postCity,
    editCity,
    deleteCity,
    cities: entities,
  };
}

export default useCitiesManagement;
