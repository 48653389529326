import useCalculationsListManagement from './useCalculationsListManagement';
import { useMemo } from 'react';
import useSubjectsManagement from '../../Subjects/hooks/useSubjectsManagement';
import useSubjectTypesManagement from '../../SubjectTypes/hooks/useSubjectTypesManagement';

function useCalculationsForList() {
  const { calculations } = useCalculationsListManagement();
  const { subjects } = useSubjectsManagement();
  const { subjectTypes } = useSubjectTypesManagement();

  return useMemo(() => (
    calculations.map((calculation) => {
      const name = calculation.variants.map((variant, index) => {
        if ('subject' in variant) {
          const subjectId = variant.subject;
          const foundSubject = subjects.find(subject => subject._id === subjectId);
          const output = foundSubject?.name || '';
          return `${output}${index !== calculation.variants.length - 1 ? ', ' : ''}`;
        }
        if ('subjectType' in variant) {
          const subjectTypeId = variant.subjectType;
          const foundSubjectType = subjectTypes.find(subjectType => subjectType._id === subjectTypeId);
          const output = foundSubjectType?.name || '';
          return `${output}${index !== calculation.variants.length - 1 ? ', ' : ''}`;
        }
        return '';
      });
      return {
        ...calculation,
        name,
      };
    })
  ),             [calculations, subjects, subjectTypes]);
}

export default useCalculationsForList;
