import City from './interfaces/City';

export interface CitiesState {
  entities: City[];
  isLoading: boolean;
  entityModal: {
    isOpened: boolean;
    entity?: City;
  };
}

export const initialState: CitiesState = {
  entities: [],
  isLoading: false,
  entityModal: {
    isOpened: false,
  },
};
