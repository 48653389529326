import useReportsManagement from '../hooks/useReportsManagement';
import { useCallback, useMemo, useState } from 'react';
import useCitiesManagement from '../../Cities/hooks/useCitiesManagement';
import { BarChartProps } from 'recharts';
import City from '../../Cities/interfaces/City';
import useFieldOfStudyTypesManagement from '../../FieldOfStudyTypes/hooks/useFieldOfStudyTypesManagement';

export function useCitiesChartsData() {
  const { cities } = useCitiesManagement();
  const { fieldOfStudyTypes } = useFieldOfStudyTypesManagement();
  const { reports } = useReportsManagement();
  const [activeCity, setActiveCity] = useState<City>();

  const citiesMap = useMemo(() => new Map(cities.map(city => [city._id, city])), [cities]);

  const fieldOfStudyTypesMap = useMemo(() => new Map(fieldOfStudyTypes.map(type => [type._id, type])), [fieldOfStudyTypes]);

  const citiesChartsData = useMemo(
    () => {
      return reports?.cities.map((cityReport) => {
        const city = citiesMap.get(cityReport._id);
        return {
          id: city?._id,
          name: city?.name,
          total: cityReport.total,
        };
      });
    },
    [citiesMap, reports],
  );

  const singleCityData = useMemo(
    () => {
      const cityReport = reports?.cities.find(city => city._id === activeCity?._id);
      if (cityReport) {
        return [
          {
            name: activeCity?.name,
            total: cityReport.total,
          },
          ...cityReport.fieldOfStudyTypes.map((fieldOfStudyTypeReport) => {
            const fieldOfStudyType = fieldOfStudyTypesMap.get(fieldOfStudyTypeReport._id);
            return {
              name: fieldOfStudyType?.name,
              total: fieldOfStudyTypeReport.total,
            };
          }),
        ];
      }
    },
    [reports, activeCity, fieldOfStudyTypesMap],
  );

  const handleBarClick: BarChartProps['onClick'] = useCallback(
    (data) => {
      const activePayload = data?.activePayload?.[0];
      const cityId = activePayload?.payload?.id;
      const city = citiesMap.get(cityId);
      setActiveCity(city);
    },
    [citiesMap, setActiveCity],
  );

  const handleBackButtonClick = useCallback(
    () => {
      setActiveCity(undefined);
    },
    [setActiveCity],
  );

  return {
    citiesChartsData,
    handleBarClick,
    handleBackButtonClick,
    activeCity,
    singleCityData,
  };
}
