import SubjectType from './interfaces/SubjectType';

export interface SubjectTypesState {
  entities: SubjectType[];
  entityModal: {
    isOpened: boolean;
    entity?: SubjectType;
  };
  isLoading: boolean;
}

export const initialState: SubjectTypesState = {
  entities: [],
  isLoading: false,
  entityModal: {
    isOpened: false,
  },
};
