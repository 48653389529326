import FieldOfStudyType from '../interfaces/FieldOfStudyType';

export interface ModalState {
  isOpened: boolean;
  entity?: FieldOfStudyType;
}

export const initialModalState: ModalState = {
  isOpened: false,
};
