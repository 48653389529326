import useCitiesManagement from './useCitiesManagement';
import { useEffect } from 'react';

function useCitiesLoading() {
  const { fetchCities } = useCitiesManagement();
  useEffect(
    () => {
      fetchCities();
    },
    [fetchCities],
  );
}

export default useCitiesLoading;
