import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { subjectTypesActions } from '../actions';
import { useCallback, useMemo } from 'react';
import SubjectType from '../interfaces/SubjectType';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';

function useSubjectTypeModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isOpened,
  } = useSelector((state: AppState) => ({
    entity: state.subjectTypes.entityModal.entity,
    isOpened: state.subjectTypes.entityModal.isOpened,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      return dispatch(subjectTypesActions.openEntityModal());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (subjectType?: SubjectType) => (
      () => {
        return dispatch(subjectTypesActions.openEntityModal(subjectType));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(subjectTypesActions.closeEntityModal());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
        order: entity.order,
      } : {
        name: '',
        order: 1,
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    handleOpen,
    handleEdit,
    handleClose,
    isEditing,
    initialValues,
    subjectType: entity,
  };
}

export default useSubjectTypeModalManagement;
