import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { voivodeshipsActions } from '../actions';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useVoivodeshipModalManagement from './useVoivodeshipsModalManagement';
import Voivodeship from '../interfaces/Voivodeship';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useVoivodeshipsManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { handleClose } = useVoivodeshipModalManagement();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.voivodeships.entities,
    isLoading: state.voivodeships.isLoading,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const fetchVoivodeships = useCallback(
    () => {
      return dispatch(voivodeshipsActions.fetchVoivodeships());
    },
    [dispatch],
  );

  const postVoivodeship = useCallback(
    (voivodeship: Voivodeship) => {
      return dispatch(voivodeshipsActions.postVoivodeship(voivodeship))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editVoivodeship = useCallback(
    (id: string, voivodeship: Voivodeship) => {
      return dispatch(voivodeshipsActions.editVoivodeship(id, voivodeship))
        .finally(() => {
          handleClose();
        });

    },
    [dispatch, handleClose],
  );

  const deleteVoivodeship = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(voivodeshipsActions.deleteVoivodeship(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchVoivodeships,
    postVoivodeship,
    editVoivodeship,
    deleteVoivodeship,
    voivodeships: entities,
  };
}

export default useVoivodeshipsManagement;
