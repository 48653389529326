import useAuthenticationManagement from '../../../modules/Authentication/hooks/useAuthenticationManagement';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useLayoutManagement() {
  const [isOnLoginPage, setIsOnLoginPage] = useState(false);
  const { isLoggedIn } = useAuthenticationManagement();
  const location = useLocation();
  useEffect(
    () => {
      const currentPath = location.pathname;
      if (currentPath === '/login') {
        setIsOnLoginPage(true);
      } else {
        setIsOnLoginPage(false);
      }
    },
    [location],
  );

  return {
    isOnLoginPage,
    shouldToolbarBeShown: isLoggedIn,
  };
}

export default useLayoutManagement;
