import React from 'react';
import List from '../../shared/components/List';
import CalculationModal from './Modal';
import useCalculationsModalManagement from './hooks/useCalculationsModalManagement';
import useCalculationsListManagement from './hooks/useCalculationsListManagement';
import useCalculationsForList from './hooks/useCalculationsForList';

const Calculations = () => {
  const { handleOpen, handleEdit } = useCalculationsModalManagement();
  const { removeCalculation } = useCalculationsListManagement();
  const calculations = useCalculationsForList();
  return (
    <div>
      <CalculationModal />
      <List
        objects={calculations}
        onRemove={removeCalculation}
        onEdit={handleEdit}
        onAddButtonClick={handleOpen}
        properties={[
          {
            key: 'name',
            title: 'Przedmioty',
          },
          {
            key: 'multiplierBasic',
            title: 'Podstawowy mnożnik',
          },
          {
            key: 'multiplierAdvanced',
            title: 'Rozszerzony mnożnik',
          },
          {
            key: 'numberOfVariantsTakenIntoAccount',
            title: 'Ilość przedmiotów brana pod uwagę',
          },
        ]}
        title="Obliczenia"
      />
    </div>
  );
};

export default Calculations;
