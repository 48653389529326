import UniversityType from './interfaces/UniversityType';

export interface UniversityTypesState {
  entities: UniversityType[];
  entityModal: {
    isOpened: boolean;
    entity?: UniversityType;
  };
  isLoading: boolean;
}

export const initialState: UniversityTypesState = {
  entities: [],
  isLoading: false,
  entityModal: {
    isOpened: false,
  },
};
