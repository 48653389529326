import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import React from 'react';
import useVoivodeshipModalManagement from '../hooks/useVoivodeshipsModalManagement';
import useVoivodeshipsManagement from '../hooks/useVoivodeshipsManagement';

const VoivodeshipModal = () => {
  const {
    isOpened,
    handleClose,
    initialValues,
    isEditing,
    voivodeship,
  } = useVoivodeshipModalManagement();
  const { postVoivodeship, editVoivodeship } = useVoivodeshipsManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Województwo"
      isEditing={isEditing}
      onCreate={postVoivodeship}
      onEdit={editVoivodeship}
      id={voivodeship?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
    </EntityModal>
  );
};

export default VoivodeshipModal;
