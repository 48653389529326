import React from 'react';
import { useCitiesChartsData } from './useCitiesChartsData';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import ReportsChart from '../ReportsChart';

const CitiesCharts = () => {
  const { citiesChartsData, handleBarClick, activeCity, singleCityData, handleBackButtonClick } = useCitiesChartsData();

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        {
          activeCity && (
            <IconButton aria-label="delete" onClick={handleBackButtonClick}>
              <BackIcon />
            </IconButton>
          )
        }
        <Typography className={styles.header} variant="h5">Miasta</Typography>
      </div>
      {
        (activeCity && singleCityData) ? (
          <ReportsChart data={singleCityData}/>
        ) : (
          <ReportsChart data={citiesChartsData} onClick={handleBarClick}/>
        )
      }
    </div>
  );
};

export default CitiesCharts;
