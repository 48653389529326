import { DepartmentsListAction } from '../actions/list';
import { ListState, initialListState } from '../state/list';

const departmentsListReducer = (
  state: ListState = initialListState,
  action: DepartmentsListAction,
) => {
  switch (action.type) {
    case 'Departments.List.ReceiveEntities':
      const { departments } = action.payload;
      return {
        ...state,
        entities: departments,
      };
    case 'Departments.List.ReceiveEntity': {
      const { department } = action.payload;
      const entities = [
        ...state.entities,
        department,
      ];
      return {
        ...state,
        entities,
      };
    }
    case 'Departments.List.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Departments.List.ModifyEntity': {
      const { department, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = department;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Departments.List.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Departments.List.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default departmentsListReducer;
