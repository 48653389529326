import React, { FunctionComponent } from 'react';
import { Field, FieldProps } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormHelperText, Grid } from '@material-ui/core';
import styles from './styles.module.scss';
import getDefaultLabel from '../../../utilities/getDefaultLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import handleChange from './handleChange';

interface Props {
  name: string;
  label?: string;
  onChange?: () => void;
}

const CheckboxInput: FunctionComponent<Props> = ({
  name,
  label,
  onChange,
}) => (
  <Grid item={true}>
    <Field
      name={name}
    >
      {({ field, meta }: FieldProps) => (
        <FormControl error={Boolean(meta.error)} className={styles.root}>
          <FormControlLabel
            control={(
              <Checkbox
                {...field}
                onChange={handleChange(field.onChange, onChange)}
                checked={field.value}
              />
            )}
            labelPlacement="end"
            label={label || getDefaultLabel(name)}
          />
          <FormHelperText
            id={`component-error-${name}`}
            className={meta.error ? '' :  styles.helperWithoutError}
          >
            {meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  </Grid>
);

export default CheckboxInput;
