import React from 'react';
import Toolbar from './Toolbar';
import Drawer from './Drawer';
import Content from './Content';
import useStyles from './useStyles';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLayoutManagement from './useLayoutManagement';
import useDrawerManagement from './useDrawerManagement';
import Loader from '../Loader';
import useAuthenticationManagement from '../../../modules/Authentication/hooks/useAuthenticationManagement';

const Layout = () => {
  const classes = useStyles();
  const {
    handleDrawerOpen,
    handleDrawerClose,
    isDrawerOpened,
  } = useDrawerManagement();
  const { shouldToolbarBeShown, isOnLoginPage } = useLayoutManagement();
  const { isLoading } = useAuthenticationManagement();

  return (
    <div className={classes.root}>
      <Loader isLoading={!isOnLoginPage && isLoading}>
          {
            shouldToolbarBeShown && (
              <>
                <Toolbar
                  open={isDrawerOpened}
                  handleDrawerOpen={handleDrawerOpen}
                />
              </>
            )
          }
          <Drawer open={isDrawerOpened} handleDrawerClose={handleDrawerClose}/>
          <Content open={isDrawerOpened}>
            <Routes/>
          </Content>
      </Loader>
      <ToastContainer autoClose={false} />
    </div>
  );
};

export default Layout;
