import User from '../Users/interfaces/User';

export interface AuthenticationState {
  isLoggedIn: boolean;
  isLoading: boolean;
  user?: User;
}

export const initialAuthenticationState: AuthenticationState = {
  isLoggedIn: false,
  isLoading: true,
};
