import React, { FunctionComponent, Fragment } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MaterialDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useStyles from '../useStyles';
import categories from './items';
import Item from './Item';

interface Props {
  open: boolean;
  handleDrawerClose: () => void;
}

const Drawer: FunctionComponent<Props> = ({
  open,
  handleDrawerClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MaterialDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {categories.map((category, index) => (
          <Fragment key={category[0]?.name}>
            {
              category.map(({ name, Icon, path }) => (
                <Item
                  key={path}
                  name={name}
                  Icon={Icon}
                  path={path}
                />
              ))
            }
            {
              index !== categories.length && (
                <Divider />
              )
            }
          </Fragment>
        ))}
      </List>
    </MaterialDrawer>
  );
};

export default Drawer;
