import { useLocation } from 'react-router';

function useCurrentLocation(path: string) {
  const location = useLocation();
  return {
    isCurrentLocation: location.pathname === path,
  };
}

export default useCurrentLocation;
