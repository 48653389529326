import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import useStyles from '../useStyles';
import useLayoutManagement from '../useLayoutManagement';

interface Props {
  open: boolean;
}

const Content: FunctionComponent<Props> = ({
  open,
  children,
}) => {
  const classes = useStyles();
  const { shouldToolbarBeShown } = useLayoutManagement();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
        [classes.withToolbar]: shouldToolbarBeShown,
      })}
    >
      {
        shouldToolbarBeShown && <div className={classes.drawerHeader} />
      }
      {children}
    </main>
  );
};

export default Content;
