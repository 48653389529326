import { useEffect } from 'react';
import useAuthenticationManagement from './useAuthenticationManagement';

function useAuthenticationLoading() {
  const { authenticate } = useAuthenticationManagement();
  useEffect(
    () => {
      authenticate();
    },
    [authenticate],
  );
}

export default useAuthenticationLoading;
