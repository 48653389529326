import React from 'react';
import useSubjectTypesLoading from './hooks/useSubjectTypesLoading';
import useSubjectTypesManagement from './hooks/useSubjectTypesManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import SubjectTypeModal from './SubjectTypeModal';
import useSubjectTypeModalManagement from './hooks/useSubjectTypeModalManagement';

const SubjectTypes = () => {
  useSubjectTypesLoading();
  const { isLoading, subjectTypes, deleteSubjectType } = useSubjectTypesManagement();
  const { handleOpen, handleEdit } = useSubjectTypeModalManagement();
  return (
    <Loader isLoading={isLoading}>
      <SubjectTypeModal />
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteSubjectType}
        objects={subjectTypes}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }, {
          key: 'order',
          title: 'Kolejność',
        }]}
      />
    </Loader>
  );
};

export default SubjectTypes;
