import React from 'react';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import useUsersManagement from './hooks/useUsersManagement';
import useUsersLoading from './hooks/useUsersLoading';
import CreateUserModal from './Modals/Create';
import useCreateModalManagement from './Modals/Create/useCreateModalManagement';
import useEditModalManagement from './Modals/Edit/useEditModalManagement';
import EditUserModal from './Modals/Edit';

const Users = () => {
  useUsersLoading();
  const { isLoading, users } = useUsersManagement();
  const { handleOpen: handleCreateModalOpen } = useCreateModalManagement();
  const { handleOpen: handleEditModalOpen } = useEditModalManagement();
  return (
    <Loader isLoading={isLoading}>
      <CreateUserModal />
      <EditUserModal />
      <List
        objects={users}
        onAddButtonClick={handleCreateModalOpen}
        onEdit={handleEditModalOpen}
        properties={[
          {
            key: 'name',
            title: 'Imię',
          },
          {
            key: 'email',
            title: 'Email',
          },
        ]}
        title="Użytkownicy"
      />
    </Loader>
  );
};

export default Users;
