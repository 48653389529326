import React from 'react';
import CheckboxInput from '../../../../shared/components/inputs/CheckboxInput';
import { useFormikContext } from 'formik';
import CalculationInForm from '../../interfaces/CalculationInForm';
import MultiplierInput from './MultiplierInput';
import useCheckboxManagement from './useCheckboxManagement';

const SeparateMultipliers = () => {
  const { values } = useFormikContext<CalculationInForm>();
  const { handleChange } = useCheckboxManagement();
  return (
    <div>
      <CheckboxInput
        name="areSeparateMultipliersTurnedOn"
        label="Podaj oddzielne przeliczniki"
        onChange={handleChange}
      />
      {
        values.areSeparateMultipliersTurnedOn && (
          <>
            {
              values.subjects.map(subjectId => (
                <MultiplierInput
                  key={`subject_${subjectId}`}
                  subjectId={subjectId}
                />
              ))
            }
            {
              values.subjectTypes.map(subjectTypeId => (
                <MultiplierInput
                  key={`subjectType_${subjectTypeId}`}
                  subjectTypeId={subjectTypeId}
                />
              ))
            }
          </>
        )
      }
    </div>
  );
};

export default SeparateMultipliers;
