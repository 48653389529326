import Subject from './interfaces/Subject';

export interface SubjectsState {
  entities: Subject[];
  isLoading: boolean;
  entityModal: {
    isOpened: boolean;
    entity?: Subject;
  };
}

export const initialState: SubjectsState = {
  entities: [],
  isLoading: false,
  entityModal: {
    isOpened: false,
  },
};
