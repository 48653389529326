import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  withMargin: {
    marginRight: '10px',
  },
  label: {
    minWidth: '100px',
  },
});

export default useStyles;
