import Action from '../../../shared/types/Action';
import ThunkAction from '../../../shared/types/ThunkAction';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import University from '../interfaces/University';
import request from '../../../shared/utilities/request';
import Method from '../../../shared/utilities/request/Method';
import { UniversitiesEditModalAction } from './editModal';

export type UniversitiesAction =
  | Action<
    'Universities.ReceiveEntities',
    {
      universities: University[],
    }
  >
  | Action<
  'Universities.ReceiveEntity',
  {
    university: University,
  }
  >
  | Action<
  'Universities.ModifyEntity',
  {
    university: University,
    id: string,
  }
  >
  | Action<
  'Universities.RemoveEntity',
  {
    id: string,
  }
  >
  | Action<'Universities.OpenCreateModal'>
  | Action<'Universities.CloseCreateModal'>
  | Action<'Universities.StartLoading'>
  | Action<'Universities.FinishLoading'>
  | UniversitiesEditModalAction;

export const universitiesActions = {
  fetchUniversities(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universitiesActions.startLoading());
      return request<University[]>('/universities?withPrivate=true')
        .then((universities) => {
          dispatch(universitiesActions.receiveEntities(universities));
        })
        .finally(() => {
          dispatch(universitiesActions.finishLoading());
        });
    };
  },
  postUniversity(university: University): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universitiesActions.startLoading());
      return request<University>('/universities', {
        method: Method.POST,
        body: university,
      })
        .then((receivedUniversity) => {
          dispatch(universitiesActions.receiveEntity(receivedUniversity));
        })
        .finally(() => {
          dispatch(universitiesActions.finishLoading());
        });
    };
  },
  editUniversity(id: string, university: University): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universitiesActions.startLoading());
      return request<University>(`/universities/${id}`, {
        method: Method.PUT,
        body: university,
      })
        .then((receivedUniversity) => {
          dispatch(universitiesActions.modifyEntity(receivedUniversity._id, receivedUniversity));
        })
        .finally(() => {
          dispatch(universitiesActions.finishLoading());
        });
    };
  },
  deleteUniversity(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universitiesActions.startLoading());
      return request<University>(`/universities/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(universitiesActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(universitiesActions.finishLoading());
        });
    };
  },
  receiveEntities(universities: University[]): UniversitiesAction {
    return {
      type: 'Universities.ReceiveEntities',
      payload: {
        universities,
      },
    };
  },
  removeEntity(id: string): UniversitiesAction {
    return {
      type: 'Universities.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  receiveEntity(university: University): UniversitiesAction {
    return {
      type: 'Universities.ReceiveEntity',
      payload: {
        university,
      },
    };
  },
  modifyEntity(id: string, university: University): UniversitiesAction {
    return {
      type: 'Universities.ModifyEntity',
      payload: {
        university,
        id,
      },
    };
  },
  openCreateModal(): UniversitiesAction {
    return {
      type: 'Universities.OpenCreateModal',
      payload: undefined,
    };
  },
  closeCreateModal(): UniversitiesAction {
    return {
      type: 'Universities.CloseCreateModal',
      payload: undefined,
    };
  },
  startLoading(): UniversitiesAction {
    return {
      type: 'Universities.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): UniversitiesAction {
    return {
      type: 'Universities.FinishLoading',
      payload: undefined,
    };
  },
};
