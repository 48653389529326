import { useState, MouseEvent } from 'react';
import useAuthenticationManagement from '../../../../../modules/Authentication/hooks/useAuthenticationManagement';

function useUserMenuManagement() {
  const { logOut } = useAuthenticationManagement();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const isOpened = Boolean(anchorElement);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleLogOut = () => {
    setAnchorElement(null);
    logOut();
  };

  return {
    handleMenuOpen,
    handleMenuClose,
    anchorElement,
    handleLogOut,
    isOpened,
  };
}

export default useUserMenuManagement;
