import React from 'react';

function useDrawerManagement() {
  const [isDrawerOpened, setIsOpened] = React.useState(false);

  const handleDrawerOpen = () => {
    setIsOpened(true);
  };

  const handleDrawerClose = () => {
    setIsOpened(false);
  };

  return {
    handleDrawerClose,
    handleDrawerOpen,
    isDrawerOpened,
  };
}

export default useDrawerManagement;
