import useUsersManagement from './useUsersManagement';
import { useEffect } from 'react';

function useUsersLoading() {
  const { fetchUsers } = useUsersManagement();
  useEffect(
    () => {
      fetchUsers();
    },
    [fetchUsers],
  );
}

export default useUsersLoading;
