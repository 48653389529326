import { CitiesAction } from './actions';
import { CitiesState, initialState } from './state';

const citiesReducer = (
  state: CitiesState = initialState,
  action: CitiesAction,
) => {
  switch (action.type) {
    case 'Cities.ReceiveEntities':
      const { cities } = action.payload;
      return {
        ...state,
        entities: cities,
      };
    case 'Cities.ReceiveEntity': {
      const { city } = action.payload;
      const entities = [
        ...state.entities,
      ];
      entities.push(city);
      return {
        ...state,
        entities,
      };
    }
    case 'Cities.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Cities.ModifyEntity': {
      const { city, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = city;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Cities.OpenEntityModal': {
      const { city } = action.payload;
      return {
        ...state,
        entityModal: {
          isOpened: true,
          entity: city,
        },
      };
    }
    case 'Cities.CloseEntityModal': {
      return {
        ...state,
        entityModal: {
          isOpened: false,
        },
      };
    }
    case 'Cities.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Cities.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default citiesReducer;
