import { useLocation } from 'react-router-dom';
import Item from '../../types/Item';
import items from './Drawer/items';

function usePageTitle() {
  const location = useLocation();
  const item = items.flat().find(
    (foundItem: Item) => foundItem.path === location.pathname,
  );
  return item?.name;
}

export default usePageTitle;
