import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    minWidth: '900px',
    outline: 'none',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    '&&': {
      padding: '10px 15px',
      maxHeight: '75vh',
      overflow: 'auto',
      flexWrap: 'nowrap',
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default useStyles;
