import Action from '../../../shared/types/Action';
import User from '../interfaces/User';

export type UsersModalsAction =
  | Action<'Users.Modal.OpenEditModal', {
    user: User,
  }>
  | Action<'Users.Modal.OpenCreateModal'>
  | Action<'Users.Modal.CloseCreateModal'>
  | Action<'Users.Modal.CloseEditModal'>;

export const usersModalsActions = {
  openEditModal(user: User): UsersModalsAction {
    return {
      type: 'Users.Modal.OpenEditModal',
      payload: {
        user,
      },
    };
  },
  openCreateModal(): UsersModalsAction {
    return {
      type: 'Users.Modal.OpenCreateModal',
      payload: undefined,
    };
  },
  closeCreateModal(): UsersModalsAction {
    return {
      type: 'Users.Modal.CloseCreateModal',
      payload: undefined,
    };
  },
  closeEditModal(): UsersModalsAction {
    return {
      type: 'Users.Modal.CloseEditModal',
      payload: undefined,
    };
  },
};
