import Voivodeship from './interfaces/Voivodeship';

export interface VoivodeshipsState {
  entities: Voivodeship[];
  isLoading: boolean;
  entityModal: {
    isOpened: boolean;
    entity?: Voivodeship;
  };
}

export const initialState: VoivodeshipsState = {
  entities: [],
  isLoading: false,
  entityModal: {
    isOpened: false,
  },
};
