import useUniversityTypesManagement from './useUniversityTypesManagement';
import { useEffect } from 'react';

function useUniversityTypesLoading() {
  const { fetchUniversityTypes } = useUniversityTypesManagement();
  useEffect(
    () => {
      fetchUniversityTypes();
    },
    [fetchUniversityTypes],
  );
}

export default useUniversityTypesLoading;
