import { UniversityTypesAction } from './actions';
import { UniversityTypesState, initialState } from './state';

const universityTypesReducer = (
  state: UniversityTypesState = initialState,
  action: UniversityTypesAction,
) => {
  switch (action.type) {
    case 'UniversityTypes.ReceiveEntities':
      const { universityTypes } = action.payload;
      return {
        ...state,
        entities: universityTypes,
      };
    case 'UniversityTypes.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'UniversityTypes.ModifyEntity': {
      const { universityType, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = universityType;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'UniversityTypes.ReceiveEntity': {
      const { universityType } = action.payload;
      const entities = [
        ...state.entities,
      ];
      entities.push(universityType);
      return {
        ...state,
        entities,
      };
    }
    case 'UniversityTypes.OpenEntityModal': {
      const { universityType } = action.payload;
      return {
        ...state,
        entityModal: {
          isOpened: true,
          entity: universityType,
        },
      };
    }
    case 'UniversityTypes.CloseEntityModal': {
      return {
        ...state,
        entityModal: {
          isOpened: false,
        },
      };
    }
    case 'UniversityTypes.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'UniversityTypes.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default universityTypesReducer;
