import { DeletePopupAction } from './actions';
import { DeletePopupState, initialDeletePopupState } from './state';

const deletePopupReducer = (
  state: DeletePopupState = initialDeletePopupState,
  action: DeletePopupAction,
) => {
  switch (action.type) {
    case 'DeletePopup.Open':
      const { onConfirm } = action.payload;
      return {
        ...state,
        onConfirm,
        isOpened: true,
      };
    case 'DeletePopup.Close': {
      return {
        ...state,
        onConfirm: undefined,
        isOpened: false,
      };
    }
    default:
      return state;
  }
};

export default deletePopupReducer;
