import React, { Dispatch, FunctionComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import useStyles from './useStyles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import handleEdit from './handleEdit';
import { Grid } from '@material-ui/core';

interface Props<> {
  isOpened: boolean;
  entityName: string;
  initialValues: {
    [name: string]: any,
  };
  onCreate: Dispatch<any>;
  onEdit: (id: string, values: any) => void;
  onClose: VoidFunction;
  isEditing?: boolean;
  id?: string;
}

const EntityModal: FunctionComponent<Props> = ({
  isOpened,
  children,
  entityName,
  initialValues,
  onCreate,
  onEdit,
  isEditing,
  onClose,
  id,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpened}
      className={classes.modal}
      onClose={onClose}
    >
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={isEditing ? handleEdit(id!, onEdit) : onCreate}
        >
          {() => (
            <Form>
              <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom={true} variant="h5" component="h2">
                    {entityName} {isEditing ? ' - Edycja' : ''}
                  </Typography>
                  <Grid
                    container={true}
                    direction="column"
                    alignItems="stretch"
                    spacing={2}
                    className={classes.form}
                  >
                    {children}
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" type="submit">
                    Zapisz
                  </Button>
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

export default EntityModal;
