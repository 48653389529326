import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useCalculationsModalManagement from '../hooks/useCalculationsModalManagement';
import useCalculationsListManagement from '../hooks/useCalculationsListManagement';
import entitiesToOptions from '../../../shared/utilities/entitiesToOptions';
import MultipleSelectInput from '../../../shared/components/inputs/MultipleSelectInput';
import useSubjectsManagement from '../../Subjects/hooks/useSubjectsManagement';
import useSubjectTypesManagement from '../../SubjectTypes/hooks/useSubjectTypesManagement';
import SeparateMultipliers from './SeparateMultipliers';

const CalculationModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, calculation } = useCalculationsModalManagement();
  const { addCalculation, editCalculation } = useCalculationsListManagement();
  const { subjects } = useSubjectsManagement();
  const { subjectTypes } = useSubjectTypesManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      isEditing={isEditing}
      id={calculation?._id}
      entityName="Obliczenie"
      onCreate={addCalculation}
      onEdit={editCalculation}
      initialValues={initialValues}
    >
      <TextInput
        name="multiplierBasic"
        type="number"
        label="Podstawowy mnożnik"
      />
      <TextInput
        name="multiplierAdvanced"
        type="number"
        label="Rozszerzony mnożnik"
      />
      <TextInput
        name="numberOfVariantsTakenIntoAccount"
        type="number"
        label="Ilość przedmiotów brana pod uwagę"
      />
      <MultipleSelectInput
        name="subjects"
        label="Przedmioty"
        options={entitiesToOptions(subjects)}
      />
      <MultipleSelectInput
        name="subjectTypes"
        label="Typy przedmiotów"
        options={entitiesToOptions(subjectTypes)}
      />
      <SeparateMultipliers />
    </EntityModal>
  );
};

export default CalculationModal;
