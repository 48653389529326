import { ChangeEvent } from 'react';
import { FormikHandlers } from 'formik/dist/types';

const handleChange = (handleFormikChange: FormikHandlers['handleChange'], onChange?: () => void) => (
  (event: ChangeEvent<HTMLInputElement>) => {
    handleFormikChange(event);
    if (onChange) {
      onChange();
    }
  }
);

export default handleChange;
