import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { departmentsListActions } from '../actions/list';
import { useCallback, useMemo } from 'react';
import Department from '../interfaces/Department';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { departmentsModalActions } from '../actions/modal';
import Calculation from '../../Calculations/interfaces/Calculation';
import FieldOfStudyToSend from '../../FieldsOfStudy/interfaces/FieldOfStudyToSend';
import Threshold from '../../Thresholds/interfaces/Threshold';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useDepartmentsManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    isLoading,
    fieldsOfStudy,
  } = useSelector((state: AppState) => ({
    entities: state.departments.list.entities,
    isLoading: state.departments.list.isLoading,
    fieldsOfStudy: state.fieldsOfStudy.list.entities,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const fieldsOfStudyToSend = useMemo(
    () => (
      fieldsOfStudy.map(
        fieldOfStudy => ({
          ...fieldOfStudy,
          calculations: fieldOfStudy.calculations.map(calculation => ({
            ...calculation,
            _id: undefined,
          }) as Omit<Calculation, '_id'>),
          thresholds: fieldOfStudy.thresholds.map(threshold => ({
            ...threshold,
            _id: undefined,
          }) as Omit<Threshold, '_id'>) ,
          _id: undefined,
        }) as FieldOfStudyToSend,
      )
    ),
    [fieldsOfStudy],
  );

  const fetchDepartments = useCallback(
    (universityId: string) => {
      return dispatch(departmentsListActions.fetchDepartments(universityId));
    },
    [dispatch],
  );

  const postDepartment = useCallback(
    (department: Department) => {
      return dispatch(departmentsListActions.postDepartment({
        ...department,
        fieldsOfStudy: fieldsOfStudyToSend,
      }))
        .then(() => {
          dispatch(departmentsModalActions.closeAndClear());
        });
    },
    [dispatch, fieldsOfStudyToSend],
  );

  const editDepartment = useCallback(
    (id: string, department: Department) => {
      return dispatch(departmentsListActions.editDepartment(id, {
        ...department,
        fieldsOfStudy: fieldsOfStudyToSend,
      }))
        .finally(() => {
          dispatch(departmentsModalActions.close());
        });
    },
    [dispatch, fieldsOfStudyToSend],
  );

  const deleteDepartment = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(departmentsListActions.deleteDepartment(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchDepartments,
    postDepartment,
    editDepartment,
    deleteDepartment,
    departments: entities,
  };
}

export default useDepartmentsManagement;
