import React from 'react';
import Calculations from '../../../Calculations';
import Agh from './Agh';
import { useFormikContext } from 'formik';
import useSubjectsLoading from '../../../Subjects/hooks/useSubjectsLoading';
import useSubjectTypesLoading from '../../../SubjectTypes/hooks/useSubjectTypesLoading';
import useSubjectsManagement from '../../../Subjects/hooks/useSubjectsManagement';

const CalculationsWrapper = () => {
  useSubjectsLoading();
  useSubjectTypesLoading();
  const { isLoading } = useSubjectsManagement();
  const { values } = useFormikContext();
  return (
    <>
      {
        !isLoading && (
          <>
            <Agh />
            {
              !values.isAgh && (
                <Calculations />
              )
            }
          </>
        )
      }
    </>
  );
};

export default CalculationsWrapper;
