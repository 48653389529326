import useVoivodeshipsManagement from './useVoivodeshipsManagement';
import { useEffect } from 'react';

function useVoivodeshipsLoading() {
  const { fetchVoivodeships } = useVoivodeshipsManagement();
  useEffect(
    () => {
      fetchVoivodeships();
    },
    [fetchVoivodeships],
  );
}

export default useVoivodeshipsLoading;
