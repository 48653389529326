import Action from '../../../shared/types/Action';
import Threshold from '../interfaces/Threshold';
import { v4 as uuidv4 } from 'uuid';

export type ThresholdsListAction =
  | Action<
    'Thresholds.List.ReceiveEntities',
    {
      thresholds: Threshold[],
    }
  >
  | Action<
    'Thresholds.List.ReceiveEntity',
    {
      threshold: Threshold,
    }
  >
  | Action<
    'Thresholds.List.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<
    'Thresholds.List.ModifyEntity',
    {
      id: string,
      threshold: Threshold,
    }
  >
  | Action<'Thresholds.List.Clear'>;

export const thresholdsListActions = {
  receiveEntity(threshold: Threshold): ThresholdsListAction {
    threshold._id = uuidv4();
    return {
      type: 'Thresholds.List.ReceiveEntity',
      payload: {
        threshold,
      },
    };
  },
  removeEntity(id: string): ThresholdsListAction {
    return {
      type: 'Thresholds.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, threshold: Threshold): ThresholdsListAction {
    return {
      type: 'Thresholds.List.ModifyEntity',
      payload: {
        id,
        threshold: {
          _id: id,
          ...threshold,
        },
      },
    };
  },
  receiveEntities(thresholds: Threshold[]): ThresholdsListAction {
    return {
      type: 'Thresholds.List.ReceiveEntities',
      payload: {
        thresholds,
      },
    };
  },
  clear(): ThresholdsListAction {
    return {
      type: 'Thresholds.List.Clear',
      payload: undefined,
    };
  },
};
