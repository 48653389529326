import Action from '../../../shared/types/Action';
import Department from '../interfaces/Department';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { fieldsOfStudyListActions } from '../../FieldsOfStudy/actions/list';

export type DepartmentsModalAction =
  | Action<'Departments.Modal.Open', {
    universityId: string,
    department?: Department,
  }>
  | Action<'Departments.Modal.Close'>;

export const departmentsModalActions = {
  closeAndClear() {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(departmentsModalActions.close());
      dispatch(fieldsOfStudyListActions.clear());
    };
  },
  open(universityId: string, department?: Department): DepartmentsModalAction {
    return {
      type: 'Departments.Modal.Open',
      payload: {
        universityId,
        department,
      },
    };
  },
  close(): DepartmentsModalAction {
    return {
      type: 'Departments.Modal.Close',
      payload: undefined,
    };
  },
};
