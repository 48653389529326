import { ModalState, initialModalState } from '../state/modal';
import { FieldsOfStudyModalAction } from '../actions/modal';

const fieldsOfStudyModalReducer = (
  state: ModalState = initialModalState,
  action: FieldsOfStudyModalAction,
) => {
  switch (action.type) {
    case 'FieldsOfStudy.Modal.Open': {
      const { fieldOfStudy } = action.payload;
      return {
        ...state,
        entity: fieldOfStudy,
        isOpened: true,
      };
    }
    case 'FieldsOfStudy.Modal.Close':
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};

export default fieldsOfStudyModalReducer;
