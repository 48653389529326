import React from 'react';
import EntityModal from '../../../../shared/components/EntityModal';
import BasicForm from '../BasicForm';
import useCreateModalManagement from './useCreateModalManagement';
import TextInput from '../../../../shared/components/inputs/TextInput';
import useUsersManagement from '../../hooks/useUsersManagement';

const CreateUserModal = () => {
  const { isOpened, handleClose, initialValues } = useCreateModalManagement();
  const { postUser } = useUsersManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Użytkownik"
      onCreate={postUser}
      onEdit={() => null}
      initialValues={initialValues}
    >
      <BasicForm />
      <TextInput
        name="password"
        type="password"
        label="Hasło"
      />
    </EntityModal>
  );
};

export default CreateUserModal;
