import { useEffect } from 'react';
import useReportsManagement from './useReportsManagement';

function useReportsLoading() {
  const { fetchReports } = useReportsManagement();
  useEffect(
    () => {
      fetchReports();
    },
    [fetchReports],
  );
}

export default useReportsLoading;
