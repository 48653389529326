import { SubjectsAction } from './actions';
import { SubjectsState, initialState } from './state';

const subjectsReducer = (
  state: SubjectsState = initialState,
  action: SubjectsAction,
) => {
  switch (action.type) {
    case 'Subjects.ReceiveEntities':
      const { subjects } = action.payload;
      return {
        ...state,
        entities: subjects,
      };
    case 'Subjects.ReceiveEntity': {
      const { subject } = action.payload;
      const entities = [
        ...state.entities,
      ];
      entities.push(subject);
      return {
        ...state,
        entities,
      };
    }
    case 'Subjects.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Subjects.ModifyEntity': {
      const { subject, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = subject;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Subjects.OpenEntityModal': {
      const { subject } = action.payload;
      return {
        ...state,
        entityModal: {
          isOpened: true,
          entity: subject,
        },
      };
    }
    case 'Subjects.CloseEntityModal': {
      return {
        ...state,
        entityModal: {
          isOpened: false,
        },
      };
    }
    case 'Subjects.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Subjects.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default subjectsReducer;
