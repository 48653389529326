import Department from '../interfaces/Department';

export interface ModalState {
  isOpened: boolean;
  entity?: Department;
  universityId?: string;
}

export const initialModalState: ModalState = {
  isOpened: false,
};
