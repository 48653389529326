import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import { reportsActions } from '../actions';
import { DateTime } from 'luxon';
import useReportsManagement from '../hooks/useReportsManagement';

function useDateManagement() {
  const dispatch = useDispatch();
  const { fetchReports } = useReportsManagement();

  const { startDate, endDate } = useSelector((state: AppState) => ({
    startDate: state.reports.startDate,
    endDate: state.reports.endDate,
  }));

  const handleStartDateChange = useCallback(
    (date: DateTime | null) => {
      dispatch(reportsActions.changeStartDate(date?.toISO() || null));
      fetchReports();
    },
    [dispatch, fetchReports],
  );

  const handleEndDateChange = useCallback(
    (date: DateTime | null) => {
      dispatch(reportsActions.changeEndDate(date?.toISO() || null));
      fetchReports();
    },
    [dispatch, fetchReports],
  );

  return {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
  };
}

export default useDateManagement;
