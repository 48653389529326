import React from 'react';
import Layout from '../Layout';
import CssBaseline from '@material-ui/core/CssBaseline';
import useAuthenticationLoading from '../../../modules/Authentication/hooks/useAuthenticationLoading';
import { HashRouter } from 'react-router-dom';
import DeletePopup from '../../../modules/DeletePopup';

const App = () => {
  useAuthenticationLoading();
  return (
    <div>
      <CssBaseline />
      <HashRouter>
        <Layout/>
      </HashRouter>
      <DeletePopup />
    </div>
  );
};

export default App;
