function useLogInFormManagement() {
  const initialValues = {
    email: '',
    password: '',
  };

  return {
    initialValues,
  };
}

export default useLogInFormManagement;
