import React from 'react';
import useCreateModalManagement from './useCreateModalManagement';
import EntityModal from '../../../../shared/components/EntityModal';
import useUniversitiesManagement from '../../hooks/useUniversitiesManagement';
import BasicForm from '../BasicForm';

const CreateUniversityModal = () => {
  const { isOpened, handleClose, initialValues } = useCreateModalManagement();
  const { postUniversity } = useUniversitiesManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Uniwersytet"
      onCreate={postUniversity}
      onEdit={() => null}
      initialValues={initialValues}
    >
      <BasicForm />
    </EntityModal>
  );
};

export default CreateUniversityModal;
