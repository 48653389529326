import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import useDepartmentsModalManagement from '../hooks/useDepartmentsModalManagement';
import useDepartmentsManagement from '../hooks/useDepartmentsManagement';
import TextInput from '../../../shared/components/inputs/TextInput';
import FieldsOfStudy from '../../FieldsOfStudy';

const DepartmentModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, department, university } = useDepartmentsModalManagement();
  const { postDepartment, editDepartment } = useDepartmentsManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      isEditing={isEditing}
      id={department?._id}
      entityName="Wydział"
      onCreate={postDepartment}
      onEdit={editDepartment}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      {
        university?.isPrivate && (
          <>
            <TextInput
              name="description"
              type="text"
              label="Opis"
              multiline={true}
            />
            <TextInput
              name="photoUrl"
              type="text"
              label="URL zdjęcia"
            />
          </>
        )
      }
      <FieldsOfStudy />
    </EntityModal>
  );
};

export default DepartmentModal;
