import React from 'react';
import useDeletePopupManagement from './hooks/useDeletePopupManagement';
import { Modal } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import useStyles from './useStyles';

const DeletePopup = () => {
  const { isOpened, handleClose, handleConfirm } = useDeletePopupManagement();
  const classes = useStyles();
  return (
    <Modal
      open={isOpened}
      className={classes.modal}
      onClose={handleClose}
    >
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom={true} variant="h5" component="h2">
            Czy jesteś pewien?
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleClose}>
            Cofnij
          </Button>
          <Button size="small" color="secondary" onClick={handleConfirm}>
            Usuń
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default DeletePopup;
