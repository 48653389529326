import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { subjectTypesActions } from '../actions';
import { useCallback } from 'react';
import SubjectType from '../interfaces/SubjectType';
import useSubjectTypeModalManagement from './useSubjectTypeModalManagement';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useSubjectTypesManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { handleClose } = useSubjectTypeModalManagement();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.subjectTypes.entities,
    isLoading: state.subjectTypes.isLoading,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const fetchSubjectTypes = useCallback(
    () => {
      return dispatch(subjectTypesActions.fetchSubjectTypes());
    },
    [dispatch],
  );

  const postSubjectType = useCallback(
    (subjectType: SubjectType) => {
      return dispatch(subjectTypesActions.postSubjectType(subjectType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editSubjectType = useCallback(
    (id: string, subjectType: SubjectType) => {
      return dispatch(subjectTypesActions.editSubjectType(id, subjectType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const deleteSubjectType = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(subjectTypesActions.deleteSubjectType(id)));
    },
    [dispatch, handleOpen],
  );

  const getSubjectTypeById = useCallback(
    (id: string) => {
      return entities.find(entity => entity._id === id);
    },
    [entities],
  );

  return {
    isLoading,
    fetchSubjectTypes,
    deleteSubjectType,
    postSubjectType,
    editSubjectType,
    getSubjectTypeById,
    subjectTypes: entities,
  };
}

export default useSubjectTypesManagement;
