import React from 'react';
import EntityModal from '../../../../shared/components/EntityModal';
import BasicForm from '../BasicForm';
import useEditModalManagement from './useEditModalManagement';
import useUsersManagement from '../../hooks/useUsersManagement';

const EditUserModal = () => {
  const { isOpened, handleClose, initialValues, user } = useEditModalManagement();
  const { patchUser } = useUsersManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Użytkownik"
      onCreate={() => null}
      onEdit={patchUser}
      initialValues={initialValues}
      id={user?._id}
      isEditing={true}
    >
      <BasicForm />
    </EntityModal>
  );
};

export default EditUserModal;
