import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { subjectsActions } from '../actions';
import Subject from '../interfaces/Subject';

function useSubjectModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isOpened,
  } = useSelector((state: AppState) => ({
    entity: state.subjects.entityModal.entity,
    isOpened: state.subjects.entityModal.isOpened,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      return dispatch(subjectsActions.openEntityModal());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (subject?: Subject) => (
      () => {
        return dispatch(subjectsActions.openEntityModal(subject));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(subjectsActions.closeEntityModal());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
        order: entity.order,
        iconName: entity.iconName,
        isRequired: entity.isRequired,
        subjectType: entity.subjectType,
        isInitial: entity.isInitial,
      } : {
        name: '',
        order: 1,
        iconName: '',
        isRequired: false,
        subjectType: '',
        isInitial: false,
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    handleOpen,
    handleEdit,
    handleClose,
    isEditing,
    initialValues,
    subject: entity,
  };
}

export default useSubjectModalManagement;
