import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import { deletePopupActions } from '../actions';

function useDeletePopupManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    isOpened,
    onConfirm,
  } = useSelector((state: AppState) => ({
    isOpened: state.deletePopup.isOpened,
    onConfirm: state.deletePopup.onConfirm,
  }));

  const handleOpen = useCallback(
    (onConfirmHandler: () => void) => {
      return dispatch(deletePopupActions.open(onConfirmHandler));
    },
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(deletePopupActions.close());
    },
    [dispatch],
  );

  const handleConfirm = useCallback(
    () => {
      onConfirm?.();
      handleClose();
    },
    [onConfirm, handleClose],
  );

  return {
    isOpened,
    handleOpen,
    handleClose,
    handleConfirm,
  };
}

export default useDeletePopupManagement;
