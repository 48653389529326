import useFieldOfStudyTypesManagement from '../../FieldOfStudyTypes/hooks/useFieldOfStudyTypesManagement';
import useReportsManagement from '../hooks/useReportsManagement';
import { useMemo } from 'react';

export function useFieldOfStudyTypesChartsData() {
  const { fieldOfStudyTypes } = useFieldOfStudyTypesManagement();
  const { reports } = useReportsManagement();

  const fieldOfStudyTypesChartsData = useMemo(
    () => {
      const fieldOfStudyTypesMap = new Map(fieldOfStudyTypes.map(type => [type._id, type]));
      return reports?.fieldOfStudyTypes.map(typeReport => ({
        name: fieldOfStudyTypesMap.get(typeReport._id)?.name,
        total: typeReport.total,
      }));
    },
    [fieldOfStudyTypes, reports],
  );

  return {
    fieldOfStudyTypesChartsData,
  };
}
