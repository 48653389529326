import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useFieldsOfStudyModalManagement from '../hooks/useFieldsOfStudyModalManagement';
import useFieldsOfStudyListManagement from '../hooks/useFieldsOfStudyListManagement';
import Thresholds from '../../Thresholds';
import SelectInput from '../../../shared/components/inputs/SelectInput';
import entitiesToOptions from '../../../shared/utilities/entitiesToOptions';
import useFieldOfStudyTypesManagement from '../../FieldOfStudyTypes/hooks/useFieldOfStudyTypesManagement';
import CalculationsWrapper from './CalculationsWrapper';

const FieldOfStudyModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, fieldOfStudy } = useFieldsOfStudyModalManagement();
  const { addFieldOfStudy, editFieldOfStudy } = useFieldsOfStudyListManagement();
  const { fieldOfStudyTypes } = useFieldOfStudyTypesManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      isEditing={isEditing}
      id={fieldOfStudy?._id}
      entityName="Kierunek"
      onCreate={addFieldOfStudy}
      onEdit={editFieldOfStudy}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      <TextInput
        name="note"
        type="text"
        label="Notatka"
        multiline={true}
      />
      <SelectInput
        name="type"
        options={entitiesToOptions(fieldOfStudyTypes)}
      />
      <CalculationsWrapper />
      <Thresholds />
    </EntityModal>
  );
};

export default FieldOfStudyModal;
