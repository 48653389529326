import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    width: '900px',
    margin: '20px auto',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
