import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Row from './Row';
import Head from './Head';
import useStyles from './useStyles';
import AddButton from '../AddButton';
import handleRemove from './handleRemove';
import { Typography } from '@material-ui/core';

interface Props<ObjectType> {
  objects: ObjectType[];
  properties: {
    key: keyof ObjectType,
    title: string,
  }[];
  onAddButtonClick?: () => void;
  onEdit?: (entity: ObjectType) => () => void;
  onRemove?: (id: string) => void;
  title?: string;
}

function List<ObjectType extends { _id: string }>({
  objects,
  properties,
  onAddButtonClick,
  onEdit,
  onRemove,
  title,
}: Props<ObjectType>) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <Typography variant="h6">{title}</Typography>
        <AddButton onClick={onAddButtonClick}/>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <Head
            properties={properties}
          />
          <TableBody>
            {objects.map(object => (
              <Row
                key={object._id}
                object={object}
                properties={properties}
                onEdit={onEdit ? onEdit(object) : () => () => null}
                onRemove={onRemove ? handleRemove(object._id, onRemove) : undefined}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default List;
