import React, { FunctionComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface Props {
  onClick?: () => void;
}

const AddButton: FunctionComponent<Props> = ({
  onClick,
}) => {
  return (
    <IconButton aria-label="add" onClick={onClick}>
      <AddCircleIcon color="primary"/>
    </IconButton>
  );
};

export default AddButton;
