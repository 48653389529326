import React from 'react';
import { useFieldOfStudyTypesChartsData } from './useFieldOfStudyTypesChartsData';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import ReportsChart from '../ReportsChart';

const FieldOfStudyTypesCharts = () => {
  const { fieldOfStudyTypesChartsData } = useFieldOfStudyTypesChartsData();

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.header} variant="h5">Typy kierunków</Typography>
      <ReportsChart data={fieldOfStudyTypesChartsData} />
    </div>
  );
};

export default FieldOfStudyTypesCharts;
