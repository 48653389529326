import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useUniversityTypeModalManagement from '../hooks/useUniversityTypeModalManagement';
import useUniversityTypesManagement from '../hooks/useUniversityTypesManagement';

const UniversityTypeModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, universityType } = useUniversityTypeModalManagement();
  const { postUniversityType, editUniversityType } = useUniversityTypesManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Typ uniwersytetu"
      isEditing={isEditing}
      onCreate={postUniversityType}
      onEdit={editUniversityType}
      id={universityType?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
    </EntityModal>
  );
};

export default UniversityTypeModal;
