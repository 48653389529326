import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import { fieldsOfStudyModalActions } from '../actions/modal';
import FieldOfStudy from '../interfaces/FieldOfStudy';
import { calculationsListActions } from '../../Calculations/actions/list';
import { thresholdsListActions } from '../../Thresholds/actions/list';

function useFieldsOfStudyModalManagement() {
  const dispatch = useDispatch();
  const {
    isOpened,
    entity,
  } = useSelector((state: AppState) => ({
    isOpened: state.fieldsOfStudy.modal.isOpened,
    entity: state.fieldsOfStudy.modal.entity,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      dispatch(fieldsOfStudyModalActions.open());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (fieldOfStudy: FieldOfStudy) => (
      () => {
        if (fieldOfStudy.calculations) {
          dispatch(calculationsListActions.receiveEntities(fieldOfStudy.calculations));
        }
        if (fieldOfStudy.thresholds) {
          dispatch(thresholdsListActions.receiveEntities(fieldOfStudy.thresholds));
        }
        dispatch(fieldsOfStudyModalActions.open(fieldOfStudy));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      dispatch(fieldsOfStudyModalActions.closeAndClear());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
        type: entity.type,
        note: entity.note,
        isAgh: Boolean(entity.aghCalculation),
        aghCalculation: entity.aghCalculation,
      } : {
        name: '',
        isAgh: false,
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    isEditing,
    initialValues,
    handleOpen,
    handleClose,
    handleEdit,
    fieldOfStudy: entity,
  };
}

export default useFieldsOfStudyModalManagement;
