import Method from './Method';
import parseOptions from './parseOptions';
import RequestOptions from './RequestOptions';
import { toast } from 'react-toastify';
import React from 'react';
import ErrorTemplate from './ErrorTemplate';

function request<T>(
  url: string,
  options: RequestOptions = {
    method: Method.GET,
  },
): Promise<T> {
  const queryString = options.params ? `?${new URLSearchParams(Object.entries(options.params)).toString()}` : '';
  return fetch(
    `${process.env.REACT_APP_API_URL}${url}${queryString}`,
    {
      ...parseOptions(options),
      credentials: 'include',
    },
  )
    .then(async (response) => {
      if (!response.ok) {
        if (!options.hideErrors) {
          const message = await response
            .json()
            .then(data => data.message);
          toast.error((
            <ErrorTemplate
              method={options.method}
              url={url}
              status={response.status}
              statusText={response.statusText}
              message={message}
            />
          ));
        }
        return Promise.reject();
      }
      if (options.method !== Method.GET) {
        toast.success(
          `${options.method} ${url} OK`,
          { autoClose: 2000 },
        );
      }
      const headers = response.headers;
      const contentType = headers.get('Content-Type');
      if (contentType?.includes('application/json')) {
        return response.json();
      }
      return Promise.resolve();
    });
}

export default request;
