import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { thresholdsListActions } from '../actions/list';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { thresholdsModalActions } from '../actions/modal';
import Threshold from '../interfaces/Threshold';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useThresholdsListManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
  } = useSelector((state: AppState) => ({
    entities: state.thresholds.list.entities,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const addThreshold = useCallback(
    (threshold: Threshold) => {
      dispatch(thresholdsListActions.receiveEntity(threshold));
      dispatch(thresholdsModalActions.close());
    },
    [dispatch],
  );

  const editThreshold = useCallback(
    (id: string, threshold: Threshold) => {
      dispatch(thresholdsListActions.modifyEntity(id, threshold));
      dispatch(thresholdsModalActions.close());
    },
    [dispatch],
  );

  const removeThreshold = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(thresholdsListActions.removeEntity(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    addThreshold,
    editThreshold,
    removeThreshold,
    thresholds: entities,
  };
}

export default useThresholdsListManagement;
