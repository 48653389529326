import Action from '../../../shared/types/Action';
import FieldOfStudyType from '../interfaces/FieldOfStudyType';
import ThunkAction from '../../../shared/types/ThunkAction';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import request from '../../../shared/utilities/request';
import Method from '../../../shared/utilities/request/Method';

export type FieldOfStudyTypeListAction =
  | Action<
    'FieldOfStudyTypes.List.ReceiveEntities',
    {
      fieldOfStudyTypes: FieldOfStudyType[],
    }
  >
  | Action<
    'FieldOfStudyTypes.List.ReceiveEntity',
    {
      fieldOfStudyType: FieldOfStudyType,
    }
  >
  | Action<
    'FieldOfStudyTypes.List.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<
    'FieldOfStudyTypes.List.ModifyEntity',
    {
      id: string,
      fieldOfStudyType: FieldOfStudyType,
    }
  >
  | Action<'FieldOfStudyTypes.List.Clear'>
  | Action<'FieldOfStudyTypes.List.StartLoading'>
  | Action<'FieldOfStudyTypes.List.FinishLoading'>;

export const fieldOfStudyTypeListActions = {
  fetchFieldOfStudyTypes(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(fieldOfStudyTypeListActions.startLoading());
      return request<FieldOfStudyType[]>('/field-of-study-types')
        .then((fieldOfStudyTypes) => {
          dispatch(fieldOfStudyTypeListActions.receiveEntities(fieldOfStudyTypes));
        })
        .finally(() => {
          dispatch(fieldOfStudyTypeListActions.finishLoading());
        });
    };
  },
  postFieldOfStudyType(fieldOfStudyType: FieldOfStudyType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(fieldOfStudyTypeListActions.startLoading());
      return request<FieldOfStudyType>('/field-of-study-types', {
        method: Method.POST,
        body: fieldOfStudyType,
      })
        .then((receivedFieldOfStudyType) => {
          dispatch(fieldOfStudyTypeListActions.receiveEntity(receivedFieldOfStudyType));
        })
        .finally(() => {
          dispatch(fieldOfStudyTypeListActions.finishLoading());
        });
    };
  },
  editFieldOfStudyType(id: string, fieldOfStudyType: FieldOfStudyType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(fieldOfStudyTypeListActions.startLoading());
      return request<FieldOfStudyType>(`/field-of-study-types/${id}`, {
        method: Method.PUT,
        body: fieldOfStudyType,
      })
        .then((receivedFieldOfStudyType) => {
          dispatch(fieldOfStudyTypeListActions.modifyEntity(receivedFieldOfStudyType._id, receivedFieldOfStudyType));
        })
        .finally(() => {
          dispatch(fieldOfStudyTypeListActions.finishLoading());
        });
    };
  },
  deleteFieldOfStudyType(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(fieldOfStudyTypeListActions.startLoading());
      return request<FieldOfStudyType>(`/field-of-study-types/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(fieldOfStudyTypeListActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(fieldOfStudyTypeListActions.finishLoading());
        });
    };
  },
  receiveEntity(fieldOfStudyType: FieldOfStudyType): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.ReceiveEntity',
      payload: {
        fieldOfStudyType,
      },
    };
  },
  removeEntity(id: string): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, fieldOfStudyType: FieldOfStudyType): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.ModifyEntity',
      payload: {
        id,
        fieldOfStudyType,
      },
    };
  },
  receiveEntities(fieldOfStudyTypes: FieldOfStudyType[]): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.ReceiveEntities',
      payload: {
        fieldOfStudyTypes,
      },
    };
  },
  clear(): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.Clear',
      payload: undefined,
    };
  },
  startLoading(): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): FieldOfStudyTypeListAction {
    return {
      type: 'FieldOfStudyTypes.List.FinishLoading',
      payload: undefined,
    };
  },
};
