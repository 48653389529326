import ThunkDispatch from '../../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { useCallback } from 'react';
import { usersModalsActions } from '../../actions/modals';

function useCreateModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const isOpened = useSelector((state: AppState) => state.users.modals.isCreateModalOpened);

  const handleOpen = useCallback(
    () => {
      return dispatch(usersModalsActions.openCreateModal());
    },
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(usersModalsActions.closeCreateModal());
    },
    [dispatch],
  );

  const initialValues = {
    name: '',
    email: '',
    password: '',
  };

  return {
    isOpened,
    handleOpen,
    handleClose,
    initialValues,
  };

}

export default useCreateModalManagement;
