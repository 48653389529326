import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { citiesActions } from '../actions';
import City from '../interfaces/City';

function useCityModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isOpened,
  } = useSelector((state: AppState) => ({
    entity: state.cities.entityModal.entity,
    isOpened: state.cities.entityModal.isOpened,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      return dispatch(citiesActions.openEntityModal());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (city?: City) => (
      () => {
        return dispatch(citiesActions.openEntityModal(city));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(citiesActions.closeEntityModal());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
        voivodeship: entity.voivodeship,
      } : {
        name: '',
        voivodeship: '',
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    handleOpen,
    handleEdit,
    handleClose,
    isEditing,
    initialValues,
    city: entity,
  };
}

export default useCityModalManagement;
