import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import FieldOfStudyType from '../../FieldOfStudyTypes/interfaces/FieldOfStudyType';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useFieldOfStudyTypeModalManagement from './useFieldOfStudyTypeModalManagement';
import { fieldOfStudyTypeListActions } from '../actions/list';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useFieldOfStudyTypesManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.fieldOfStudyTypes.list.entities,
    isLoading: state.fieldOfStudyTypes.list.isLoading,
  }));
  const { handleClose } = useFieldOfStudyTypeModalManagement();
  const { handleOpen } = useDeletePopupManagement();

  const fetchFieldOfStudyTypes = useCallback(
    () => {
      return dispatch(fieldOfStudyTypeListActions.fetchFieldOfStudyTypes());
    },
    [dispatch],
  );

  const postFieldOfStudyType = useCallback(
    (fieldOfStudyType: FieldOfStudyType) => {
      return dispatch(fieldOfStudyTypeListActions.postFieldOfStudyType(fieldOfStudyType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editFieldOfStudyType = useCallback(
    (id: string, fieldOfStudyType: FieldOfStudyType) => {
      return dispatch(fieldOfStudyTypeListActions.editFieldOfStudyType(id, fieldOfStudyType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const deleteFieldOfStudyType = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(fieldOfStudyTypeListActions.deleteFieldOfStudyType(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchFieldOfStudyTypes,
    postFieldOfStudyType,
    editFieldOfStudyType,
    deleteFieldOfStudyType,
    fieldOfStudyTypes: entities,
  };
}

export default useFieldOfStudyTypesManagement;
