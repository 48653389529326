import Action from '../../../shared/types/Action';
import Calculation from '../interfaces/Calculation';

export type CalculationsModalAction =
  | Action<'Calculations.Modal.Open', {
    calculation?: Calculation,
  }>
  | Action<'Calculations.Modal.Close'>;

export const calculationsModalActions = {
  open(calculation?: Calculation): CalculationsModalAction {
    return {
      type: 'Calculations.Modal.Open',
      payload: {
        calculation,
      },
    };
  },
  close(): CalculationsModalAction {
    return {
      type: 'Calculations.Modal.Close',
      payload: undefined,
    };
  },
};
