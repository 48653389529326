import { EditModalState, editModalInitialState } from '../state';
import { UniversitiesEditModalAction } from '../actions/editModal';

const universitiesEditModalReducer = (
  state: EditModalState = editModalInitialState,
  action: UniversitiesEditModalAction,
): EditModalState => {
  switch (action.type) {
    case 'Universities.EditModal.Open':
      const { university } = action.payload;
      return {
        ...state,
        isOpened: true,
        entity: university,
      };
    case 'Universities.EditModal.Close': {
      return {
        ...state,
        isOpened: false,
      };
    }
    default:
      return state;
  }
};

export default universitiesEditModalReducer;
