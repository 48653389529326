import { ModalState, initialModalState } from '../state/modal';
import { ThresholdsModalAction } from '../actions/modal';

const thresholdsModalReducer = (
  state: ModalState = initialModalState,
  action: ThresholdsModalAction,
) => {
  switch (action.type) {
    case 'Thresholds.Modal.Open': {
      const { threshold } = action.payload;
      return {
        ...state,
        entity: threshold,
        isOpened: true,
      };
    }
    case 'Thresholds.Modal.Close':
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};

export default thresholdsModalReducer;
