import Action from '../../shared/types/Action';

export type DeletePopupAction =
  | Action<
  'DeletePopup.Open',
  {
    onConfirm: () => void;
  }
  >
  | Action<
  'DeletePopup.Close'
  >;

export const deletePopupActions = {
  open(onConfirm: () => void): DeletePopupAction {
    return {
      type: 'DeletePopup.Open',
      payload: {
        onConfirm,
      },
    };
  },
  close(): DeletePopupAction {
    return {
      type: 'DeletePopup.Close',
      payload: undefined,
    };
  },
};
