import useSubjectTypesManagement from './useSubjectTypesManagement';
import { useEffect } from 'react';

function useSubjectTypesLoading() {
  const { fetchSubjectTypes } = useSubjectTypesManagement();
  useEffect(
    () => {
      fetchSubjectTypes();
    },
    [fetchSubjectTypes],
  );
}

export default useSubjectTypesLoading;
