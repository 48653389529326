import RequestOptions from './RequestOptions';

function parseOptions(requestOptions: RequestOptions): RequestInit {
  const bodyOptions: Partial<RequestInit> = {
    headers: {},
  };
  if (requestOptions.body) {
    bodyOptions.body = JSON.stringify(requestOptions.body);
    bodyOptions.headers = {
      'Content-Type': 'application/json',
    };
  }
  return {
    ...requestOptions,
    body: bodyOptions.body,
    headers: {
      ...bodyOptions.headers,
    },
  };
}

export default parseOptions;
