import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useThresholdsModalManagement from '../hooks/useThresholdsModalManagement';
import useThresholdsListManagement from '../hooks/useThresholdsListManagement';

const ThresholdModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, threshold } = useThresholdsModalManagement();
  const { addThreshold, editThreshold } = useThresholdsListManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      isEditing={isEditing}
      id={threshold?._id}
      entityName="Obliczenie"
      onCreate={addThreshold}
      onEdit={editThreshold}
      initialValues={initialValues}
    >
      <TextInput
        name="year"
        label="Rok"
      />
      <TextInput
        name="value"
        type="number"
        label="Próg"
      />
    </EntityModal>
  );
};

export default ThresholdModal;
