import ThunkDispatch from '../../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { useCallback } from 'react';
import { universitiesActions } from '../../actions';

function useCreateModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const isOpened = useSelector((state: AppState) => state.universities.createModal.isOpened);

  const handleOpen = useCallback(
    () => {
      return dispatch(universitiesActions.openCreateModal());
    },
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(universitiesActions.closeCreateModal());
    },
    [dispatch],
  );

  const initialValues = {
    name: '',
    isPrivate: false,
  };

  return {
    isOpened,
    handleOpen,
    handleClose,
    initialValues,
  };

}

export default useCreateModalManagement;
