import Option from '../types/Option';

function entitiesToOptions<EntityType extends { _id: string; name: string; }>(entities: EntityType[]): Option<string>[] {
  return entities.map(entity => ({
    label: entity.name,
    value: entity._id,
  }));
}

export default entitiesToOptions;
