import { UsersListAction } from '../actions/list';
import { ListState, initialListState } from '../state/list';

const usersListReducer = (
  state: ListState = initialListState,
  action: UsersListAction,
) => {
  switch (action.type) {
    case 'Users.List.ReceiveEntities':
      const { users } = action.payload;
      return {
        ...state,
        entities: users,
      };
    case 'Users.List.ReceiveEntity': {
      const { user } = action.payload;
      const entities = [
        ...state.entities,
        user,
      ];
      return {
        ...state,
        entities,
      };
    }
    case 'Users.List.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Users.List.ModifyEntity': {
      const { user, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = user;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Users.List.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Users.List.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default usersListReducer;
