import React, { FunctionComponent } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import useStyles from './useStyles';

interface Props {
  properties: {
    key: number | symbol | string,
    title: string,
  }[];
}

const Head: FunctionComponent<Props> = ({
  properties,
}) => {
  const classes = useStyles();
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {
          properties.map(property => (
            <TableCell
              key={String(property.key)}
              className={classes.tableCell}
            >
              {property.title}
            </TableCell>
          ))
        }
        <TableCell align="center" className={classes.toolsCell}/>
      </TableRow>
    </TableHead>
  );
};

export default Head;
