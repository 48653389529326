import React from 'react';
import useUniversitiesLoading from './hooks/useUniversitiesLoading';
import useUniversitiesManagement from './hooks/useUniversitiesManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import CreateUniversityModal from './Modals/Create';
import useCreateModalManagement from './Modals/Create/useCreateModalManagement';
import useCitiesLoading from '../Cities/hooks/useCitiesLoading';
import useUniversityTypesLoading from '../UniversityTypes/hooks/useUniversityTypesLoading';
import EditUniversityModal from './Modals/Edit';
import useEditModalManagement from './Modals/Edit/useEditModalManagement';

const Universities = () => {
  useUniversitiesLoading();
  useCitiesLoading();
  useUniversityTypesLoading();

  const { isLoading, universities, deleteUniversity } = useUniversitiesManagement();
  const { handleOpen } = useCreateModalManagement();
  const { handleOpen: handleEditModalOpen } = useEditModalManagement();

  return (
    <Loader isLoading={isLoading}>
      <CreateUniversityModal />
      <EditUniversityModal />
      <List
        objects={universities}
        onRemove={deleteUniversity}
        onAddButtonClick={handleOpen}
        onEdit={handleEditModalOpen}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default Universities;
