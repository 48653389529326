import Reports from './interfaces/Reports';

export interface ReportsState {
  entity: Reports | null;
  isLoading: boolean;
  startDate: string | null;
  endDate: string | null;
}

export const initialState: ReportsState = {
  entity: null,
  isLoading: false,
  startDate: null,
  endDate: null,
};
