import { AuthenticationState, initialAuthenticationState } from './state';
import { AuthenticationAction } from './actions';

const authenticationReducer = (
  state: AuthenticationState = initialAuthenticationState,
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case 'Authentication.ReceiveUser':
      const { user } = action.payload;
      return {
        ...state,
        user,
        isLoggedIn: true,
      };
    case 'Authentication.RemoveUser':
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
      };
    case 'Authentication.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Authentication.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default authenticationReducer;
