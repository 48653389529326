import Action from '../../../shared/types/Action';
import Calculation from '../interfaces/Calculation';
import { v4 as uuidv4 } from 'uuid';

export type CalculationsListAction =
  | Action<
    'Calculations.List.ReceiveEntities',
    {
      calculations: Calculation[],
    }
  >
  | Action<
    'Calculations.List.ReceiveEntity',
    {
      calculation: Calculation,
    }
  >
  | Action<
    'Calculations.List.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<
    'Calculations.List.ModifyEntity',
    {
      id: string,
      calculation: Calculation,
    }
  >
  | Action<'Calculations.List.Clear'>;

export const calculationsListActions = {
  receiveEntity(calculation: Calculation): CalculationsListAction {
    calculation._id = uuidv4();
    return {
      type: 'Calculations.List.ReceiveEntity',
      payload: {
        calculation,
      },
    };
  },
  removeEntity(id: string): CalculationsListAction {
    return {
      type: 'Calculations.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, calculation: Calculation): CalculationsListAction {
    return {
      type: 'Calculations.List.ModifyEntity',
      payload: {
        id,
        calculation: {
          _id: id,
          ...calculation,
        },
      },
    };
  },
  receiveEntities(calculations: Calculation[]): CalculationsListAction {
    return {
      type: 'Calculations.List.ReceiveEntities',
      payload: {
        calculations,
      },
    };
  },
  clear(): CalculationsListAction {
    return {
      type: 'Calculations.List.Clear',
      payload: undefined,
    };
  },
};
