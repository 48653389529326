import { combineReducers } from 'redux';
import universities from '../modules/Universities/reducer';
import universityTypes from '../modules/UniversityTypes/reducer';
import cities from '../modules/Cities/reducer';
import voivodeships from '../modules/Voivodeships/reducer';
import subjects from '../modules/Subjects/reducer';
import departments from '../modules/Departments/reducer';
import subjectTypes from '../modules/SubjectTypes/reducer';
import fieldsOfStudy from '../modules/FieldsOfStudy/reducer';
import calculations from '../modules/Calculations/reducer';
import thresholds from '../modules/Thresholds/reducer';
import authentication from '../modules/Authentication/reducer';
import users from '../modules/Users/reducer';
import reports from '../modules/Reports/reducer';
import fieldOfStudyTypes from '../modules/FieldOfStudyTypes/reducer';
import deletePopup from '../modules/DeletePopup/reducer';
import { DepartmentsState } from '../modules/Departments/state';
import { UniversitiesState } from '../modules/Universities/state';
import { UniversityTypesState } from '../modules/UniversityTypes/state';
import { CitiesState } from '../modules/Cities/state';
import { VoivodeshipsState } from '../modules/Voivodeships/state';
import { SubjectsState } from '../modules/Subjects/state';
import { SubjectTypesState } from '../modules/SubjectTypes/state';
import { FieldsOfStudyState } from '../modules/FieldsOfStudy/state';
import { CalculationsState } from '../modules/Calculations/state';
import { ThresholdsState } from '../modules/Thresholds/state';
import { AuthenticationState } from '../modules/Authentication/state';
import { UsersState } from '../modules/Users/state';
import { FieldOfStudyTypesState } from '../modules/FieldOfStudyTypes/state';
import { ReportsState } from '../modules/Reports/state';
import { DeletePopupState } from '../modules/DeletePopup/state';

export interface AppState {
  universities: UniversitiesState;
  universityTypes: UniversityTypesState;
  cities: CitiesState;
  voivodeships: VoivodeshipsState;
  subjects: SubjectsState;
  subjectTypes: SubjectTypesState;
  departments: DepartmentsState;
  fieldsOfStudy: FieldsOfStudyState;
  calculations: CalculationsState;
  thresholds: ThresholdsState;
  authentication: AuthenticationState;
  users: UsersState;
  fieldOfStudyTypes: FieldOfStudyTypesState;
  reports: ReportsState;
  deletePopup: DeletePopupState;
}

export default combineReducers({
  universities,
  universityTypes,
  cities,
  voivodeships,
  subjects,
  departments,
  subjectTypes,
  fieldsOfStudy,
  calculations,
  thresholds,
  authentication,
  users,
  fieldOfStudyTypes,
  reports,
  deletePopup,
});
