import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';
import Voivodeship from './interfaces/Voivodeship';

export type VoivodeshipsAction =
  | Action<
    'Voivodeships.ReceiveEntities',
    {
      voivodeships: Voivodeship[],
    }
  >
  | Action<
  'Voivodeships.ReceiveEntity',
  {
    voivodeship: Voivodeship;
  }
  >
  | Action<
    'Voivodeships.ModifyEntity',
    {
      id: string,
      voivodeship: Voivodeship;
    }
  >
  | Action<
    'Voivodeships.RemoveEntity',
    {
      id: string;
    }
  >
  | Action<
    'Voivodeships.OpenEntityModal',
    {
      voivodeship?: Voivodeship;
    }
  >
  | Action<'Voivodeships.CloseEntityModal'>
  | Action<'Voivodeships.StartLoading'>
  | Action<'Voivodeships.FinishLoading'>;

export const voivodeshipsActions = {
  fetchVoivodeships(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(voivodeshipsActions.startLoading());
      return request<Voivodeship[]>('/voivodeships')
        .then((voivodeship) => {
          dispatch(voivodeshipsActions.receiveEntities(voivodeship));
        })
        .finally(() => {
          dispatch(voivodeshipsActions.finishLoading());
        });
    };
  },
  postVoivodeship(voivodeship: Voivodeship): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(voivodeshipsActions.startLoading());
      return request<Voivodeship>('/voivodeships', {
        method: Method.POST,
        body: voivodeship,
      })
        .then((receivedVoivodeships) => {
          dispatch(voivodeshipsActions.receiveEntity(receivedVoivodeships));
        })
        .finally(() => {
          dispatch(voivodeshipsActions.finishLoading());
        });
    };
  },
  editVoivodeship(id: string, voivodeship: Voivodeship): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(voivodeshipsActions.startLoading());
      return request<Voivodeship>(`/voivodeships/${id}`, {
        method: Method.PUT,
        body: voivodeship,
      })
        .then((receivedVoivodeships) => {
          dispatch(voivodeshipsActions.modifyEntity(receivedVoivodeships._id, receivedVoivodeships));
        })
        .finally(() => {
          dispatch(voivodeshipsActions.finishLoading());
        });
    };
  },
  deleteVoivodeship(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(voivodeshipsActions.startLoading());
      return request<Voivodeship[]>(`/voivodeships/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(voivodeshipsActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(voivodeshipsActions.finishLoading());
        });
    };
  },
  receiveEntities(voivodeships: Voivodeship[]): VoivodeshipsAction {
    return {
      type: 'Voivodeships.ReceiveEntities',
      payload: {
        voivodeships,
      },
    };
  },
  receiveEntity(voivodeship: Voivodeship): VoivodeshipsAction {
    return {
      type: 'Voivodeships.ReceiveEntity',
      payload: {
        voivodeship,
      },
    };
  },
  modifyEntity(id: string, voivodeship: Voivodeship): VoivodeshipsAction {
    return {
      type: 'Voivodeships.ModifyEntity',
      payload: {
        id,
        voivodeship,
      },
    };
  },
  removeEntity(id: string): VoivodeshipsAction {
    return {
      type: 'Voivodeships.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  openEntityModal(voivodeship?: Voivodeship): VoivodeshipsAction {
    return {
      type: 'Voivodeships.OpenEntityModal',
      payload: {
        voivodeship,
      },
    };
  },
  closeEntityModal(): VoivodeshipsAction {
    return {
      type: 'Voivodeships.CloseEntityModal',
      payload: undefined,
    };
  },
  startLoading(): VoivodeshipsAction {
    return {
      type: 'Voivodeships.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): VoivodeshipsAction {
    return {
      type: 'Voivodeships.FinishLoading',
      payload: undefined,
    };
  },
};
