import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import FieldOfStudyType from '../interfaces/FieldOfStudyType';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { fieldOfStudyTypeModalActions } from '../actions/modal';

function useFieldOfStudyTypeModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isOpened,
  } = useSelector((state: AppState) => ({
    entity: state.fieldOfStudyTypes.modal.entity,
    isOpened: state.fieldOfStudyTypes.modal.isOpened,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      return dispatch(fieldOfStudyTypeModalActions.open());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (fieldOfStudyType?: FieldOfStudyType) => (
      () => {
        return dispatch(fieldOfStudyTypeModalActions.open(fieldOfStudyType));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(fieldOfStudyTypeModalActions.close());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
      } : {
        name: '',
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    handleOpen,
    handleEdit,
    handleClose,
    isEditing,
    initialValues,
    fieldOfStudyType: entity,
  };
}

export default useFieldOfStudyTypeModalManagement;
