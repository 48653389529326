import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useFieldOfStudyTypeModalManagement from '../hooks/useFieldOfStudyTypeModalManagement';
import useFieldOfStudyTypesManagement from '../hooks/useFieldOfStudyTypesManagement';

const FieldOfStudyTypeModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, fieldOfStudyType } = useFieldOfStudyTypeModalManagement();
  const { postFieldOfStudyType, editFieldOfStudyType } = useFieldOfStudyTypesManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Typ kierunku"
      isEditing={isEditing}
      onCreate={postFieldOfStudyType}
      onEdit={editFieldOfStudyType}
      id={fieldOfStudyType?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
    </EntityModal>
  );
};

export default FieldOfStudyTypeModal;
