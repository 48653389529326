import React from 'react';
import TextInput from '../../../../shared/components/inputs/TextInput';

const BasicForm = () => {
  return (
    <>
      <TextInput
        name="name"
        type="text"
        label="Imię"
      />
      <TextInput
        name="email"
        type="text"
        label="Email"
      />
    </>
  );
};

export default BasicForm;
