import React, { FunctionComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
}

const Loader: FunctionComponent<Props> = ({
  isLoading,
  children,
}) => {
  return isLoading ?
    (
      <div className={styles.wrapper}>
        <CircularProgress />
      </div>
    )
    : (
      <>
        {children}
      </>
    );
};

export default Loader;
