import React from 'react';
import Card from '@material-ui/core/Card';
import useStyles from './useStyles';
import useLogInFormManagement from './useLogInFormManagement';
import { Form, Formik } from 'formik';
import useAuthenticationManagement from '../Authentication/hooks/useAuthenticationManagement';
import TextInput from '../../shared/components/inputs/TextInput';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const LogInForm = () => {
  const classes = useStyles();
  const { initialValues } = useLogInFormManagement();
  const { logIn, isLoading } = useAuthenticationManagement();
  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <Formik
          initialValues={initialValues}
          onSubmit={logIn}
        >
          {() => (
            <Form>
              <div className={classes.logoWrapper}>
                <img className={classes.logo} alt="logo" src="/logo.svg" />
              </div>
              <CardContent>
                <TextInput
                  name="email"
                  type="email"
                  label="Email"
                />
                <TextInput
                  name="password"
                  type="password"
                  label="Password"
                />
              </CardContent>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Zaloguj
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default LogInForm;
