import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import React from 'react';
import useCitiesManagement from '../hooks/useCitiesManagement';
import useCityModalManagement from '../hooks/useCityModalManagement';
import useVoivodeshipsManagement from '../../Voivodeships/hooks/useVoivodeshipsManagement';
import SelectInput from '../../../shared/components/inputs/SelectInput';
import entitiesToOptions from '../../../shared/utilities/entitiesToOptions';

const CityModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, city } = useCityModalManagement();
  const { postCity, editCity } = useCitiesManagement();
  const { voivodeships } = useVoivodeshipsManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Miasto"
      isEditing={isEditing}
      onCreate={postCity}
      onEdit={editCity}
      id={city?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      <SelectInput
        name="voivodeship"
        options={entitiesToOptions(voivodeships)}
      />
    </EntityModal>
  );
};

export default CityModal;
