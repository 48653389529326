import useSubjectTypesManagement from '../../../../SubjectTypes/hooks/useSubjectTypesManagement';
import useSubjectsManagement from '../../../../Subjects/hooks/useSubjectsManagement';
import { useMemo } from 'react';

function useInputsManagement(
  subjectId?: string,
  subjectTypeId?: string,
) {
  const { getSubjectTypeById } = useSubjectTypesManagement();
  const { getSubjectById } = useSubjectsManagement();
  return useMemo(
    () => {
      if (subjectId) {
        const subject = getSubjectById(subjectId);
        return {
          name: subject?.name,
          multiplierBasicName: `subjectsMultipliers.${subjectId}.multiplierBasic`,
          multiplierAdvancedName: `subjectsMultipliers.${subjectId}.multiplierAdvanced`,
        };
      }
      if (subjectTypeId) {
        const subjectType = getSubjectTypeById(subjectTypeId);
        return {
          name: subjectType?.name,
          multiplierBasicName: `subjectsMultipliers.${subjectTypeId}.multiplierBasic`,
          multiplierAdvancedName: `subjectsMultipliers.${subjectTypeId}.multiplierAdvanced`,
        };
      }
      return {
        name: '',
        multiplierBasicName: '',
        multiplierAdvancedName: '',
      };
    },
    [subjectTypeId, subjectId, getSubjectTypeById, getSubjectById],
  );
}

export default useInputsManagement;
