import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { usersListActions } from '../actions/list';
import RegistrationData from '../interfaces/RegistrationData';
import useCreateModalManagement from '../Modals/Create/useCreateModalManagement';
import User from '../interfaces/User';
import useEditModalManagement from '../Modals/Edit/useEditModalManagement';

function useUsersManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.users.list.entities,
    isLoading: state.users.list.isLoading,
  }));
  const {
    handleClose: handleCreateModalClose,
  } = useCreateModalManagement();
  const {
    handleClose: handleEditModalClose,
  } = useEditModalManagement();

  const fetchUsers = useCallback(
    () => {
      return dispatch(usersListActions.fetchUsers());
    },
    [dispatch],
  );

  const postUser = useCallback(
    (registrationData: RegistrationData) => {
      return dispatch(usersListActions.postUser(registrationData))
        .finally(() => {
          handleCreateModalClose();
        });
    },
    [dispatch, handleCreateModalClose],
  );

  const patchUser = useCallback(
    (id:string, user: User) => {
      return dispatch(usersListActions.patchUser(id, user))
        .finally(() => {
          handleEditModalClose();
        });
    },
    [dispatch, handleEditModalClose],
  );

  return {
    isLoading,
    fetchUsers,
    postUser,
    patchUser,
    users: entities,
  };
}

export default useUsersManagement;
