import React from 'react';
import { useFormikContext } from 'formik';
import CheckboxInput from '../../../../../shared/components/inputs/CheckboxInput';
import SelectInput from '../../../../../shared/components/inputs/SelectInput';
import entitiesToOptions from '../../../../../shared/utilities/entitiesToOptions';
import useSubjectsManagement from '../../../../Subjects/hooks/useSubjectsManagement';
import MultipleSelectInput from '../../../../../shared/components/inputs/MultipleSelectInput';
import useSubjectTypesManagement from '../../../../SubjectTypes/hooks/useSubjectTypesManagement';
import styles from './styles.module.scss';

const Agh = () => {
  const { values } = useFormikContext();
  const { subjects } = useSubjectsManagement();
  const { subjectTypes } = useSubjectTypesManagement();
  return (
    <div>
      <CheckboxInput
        name="isAgh"
        label="AGH"
      />
      {
        values.isAgh && (
          <div className={styles.inputWrapper}>
            <SelectInput
              name="aghCalculation.math"
              label="Podaj matematykę"
              options={entitiesToOptions(subjects)}
            />
            <MultipleSelectInput
              name="aghCalculation.otherSubjects"
              label="Inne przedmioty"
              options={entitiesToOptions(subjects)}
            />
            <MultipleSelectInput
              name="aghCalculation.subjectTypes"
              label="Typy przedmiotów"
              options={entitiesToOptions(subjectTypes)}
            />
          </div>
        )
      }
    </div>
  );
};

export default Agh;
