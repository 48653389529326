import { SubjectTypesAction } from './actions';
import { SubjectTypesState, initialState } from './state';

const subjectTypesReducer = (
  state: SubjectTypesState = initialState,
  action: SubjectTypesAction,
) => {
  switch (action.type) {
    case 'SubjectTypes.ReceiveEntities': {
      const { subjectTypes } = action.payload;
      return {
        ...state,
        entities: subjectTypes,
      };
    }
    case 'SubjectTypes.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'SubjectTypes.ModifyEntity': {
      const { subjectType, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = subjectType;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'SubjectTypes.ReceiveEntity': {
      const { subjectType } = action.payload;
      const entities = [
        ...state.entities,
      ];
      entities.push(subjectType);
      return {
        ...state,
        entities,
      };
    }
    case 'SubjectTypes.OpenEntityModal': {
      const { subjectType } = action.payload;
      return {
        ...state,
        entityModal: {
          isOpened: true,
          entity: subjectType,
        },
      };
    }
    case 'SubjectTypes.CloseEntityModal': {
      return {
        ...state,
        entityModal: {
          isOpened: false,
        },
      };
    }
    case 'SubjectTypes.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'SubjectTypes.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default subjectTypesReducer;
