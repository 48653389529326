import React from 'react';
import styles from './styles.module.scss';
import {
  DateTimePicker,
} from '@material-ui/pickers';
import useDateManagement from './useDateManagement';

const DateRange = () => {
  const {
    startDate,
    endDate,
    handleEndDateChange,
    handleStartDateChange,
  } = useDateManagement();
  return (
    <div className={styles.wrapper}>
      <DateTimePicker
        value={startDate}
        onChange={handleStartDateChange}
        ampm={false}
        clearable={true}
        label="Od"
        maxDate={endDate || undefined}
      />
      <DateTimePicker
        value={endDate}
        onChange={handleEndDateChange}
        ampm={false}
        clearable={true}
        label="Do"
        minDate={startDate || undefined}
      />
    </div>
  );
};

export default DateRange;
