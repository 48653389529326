import useSubjectsManagement from './useSubjectsManagement';
import { useEffect } from 'react';

function useSubjectsLoading() {
  const { fetchSubjects } = useSubjectsManagement();
  useEffect(
    () => {
      fetchSubjects();
    },
    [fetchSubjects],
  );
}

export default useSubjectsLoading;
