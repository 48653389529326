import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import { calculationsModalActions } from '../actions/modal';
import Calculation from '../interfaces/Calculation';
import MultiplierDictionary from '../interfaces/MultiplierDictionary';

function useCalculationsModalManagement() {
  const dispatch = useDispatch();
  const {
    isOpened,
    entity,
  } = useSelector((state: AppState) => ({
    isOpened: state.calculations.modal.isOpened,
    entity: state.calculations.modal.entity,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      dispatch(calculationsModalActions.open());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (fieldOfStudy?: Calculation) => (
      () => {
        dispatch(calculationsModalActions.open(fieldOfStudy));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      dispatch(calculationsModalActions.close(),
    );
    },
    [dispatch],
  );

  const getSubjectAndSubjectTypes = useCallback(
    () => {
      return (entity?.variants ?? []).reduce(
        (output, variant) => {
          const { subjects, subjectTypes, subjectsMultipliers, subjectTypesMultipliers } = output;
          if ('subject' in variant) {
            subjects.push(
              variant.subject,
            );
            if (variant.multiplierBasic || variant.multiplierAdvanced) {
              subjectsMultipliers[variant.subject] = {
                multiplierBasic: variant.multiplierBasic,
                multiplierAdvanced: variant.multiplierAdvanced,
              };
            }
          } else if ('subjectType' in variant) {
            subjectTypes.push(
              variant.subjectType,
            );
            if (variant.multiplierBasic || variant.multiplierAdvanced) {
              subjectTypesMultipliers[variant.subjectType] = {
                multiplierBasic: variant.multiplierBasic,
                multiplierAdvanced: variant.multiplierAdvanced,
              };
            }
          }
          return {
            subjects,
            subjectTypes,
            subjectsMultipliers,
            subjectTypesMultipliers,
          };
        },
        {
          subjects: [] as string[],
          subjectTypes: [] as string[],
          subjectsMultipliers: {} as MultiplierDictionary,
          subjectTypesMultipliers: {} as MultiplierDictionary,
        },
      );
    },
    [entity],
  );

  const initialValues = useMemo(
    () => {
      const { subjects, subjectTypes, subjectsMultipliers, subjectTypesMultipliers } = getSubjectAndSubjectTypes();
      return isEditing && entity ? {
        subjects,
        subjectTypes,
        subjectsMultipliers,
        subjectTypesMultipliers,
        multiplierBasic: entity.multiplierBasic,
        multiplierAdvanced: entity.multiplierAdvanced,
        numberOfVariantsTakenIntoAccount: entity.numberOfVariantsTakenIntoAccount,
        areSeparateMultipliersTurnedOn: Boolean(
          Object.keys(subjectsMultipliers).length || Object.keys(subjectTypesMultipliers).length,
        ),
      } : {
        multiplierBasic: 0,
        multiplierAdvanced: 0,
        numberOfVariantsTakenIntoAccount: 1,
        areSeparateMultipliersTurnedOn: false,
        subjects: [],
        subjectTypes: [],
        subjectsMultipliers: {},
        subjectTypesMultipliers: {},
      };
    },
    [entity, isEditing, getSubjectAndSubjectTypes],
  );

  return {
    isOpened,
    isEditing,
    initialValues,
    handleOpen,
    handleClose,
    handleEdit,
    calculation: entity,
  };
}

export default useCalculationsModalManagement;
