import React, { FunctionComponent } from 'react';
import TextInput from '../../../../../shared/components/inputs/TextInput';
import useInputsManagement from './useInputsManagement';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';

interface Props {
  subjectTypeId?: string;
  subjectId?: string;
}

const MultiplierInput: FunctionComponent<Props> = ({
  subjectTypeId,
  subjectId,
}) => {
  const {
    name,
    multiplierBasicName,
    multiplierAdvancedName,
  } = useInputsManagement(subjectId, subjectTypeId);
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Typography
        gutterBottom={true}
        className={`${styles.withMargin} ${styles.label}`}
      >
        {name}
      </Typography>
      <TextInput
        className={styles.withMargin}
        label="Podstawowy mnożnik"
        name={multiplierBasicName}
        type="number"
      />
      <TextInput
        label="Rozszerzony mnożnik"
        name={multiplierAdvancedName}
        type="number"
      />
    </div>
  );
};

export default MultiplierInput;
