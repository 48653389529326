import Action from '../../shared/types/Action';
import Reports from './interfaces/Reports';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import request from '../../shared/utilities/request';
import { AppState } from '../../store/reducers';
import Method from '../../shared/utilities/request/Method';

export type ReportsAction =
  | Action<
  'Reports.ReceiveEntity',
  {
    reports: Reports,
  }
  >
  | Action<
  'Reports.ChangeStartDate',
  {
    startDate: string | null,
  }
  >
  | Action<
  'Reports.ChangeEndDate',
  {
    endDate: string | null,
  }
  >
  | Action<'Reports.StartLoading'>
  | Action<'Reports.FinishLoading'>;

export const reportsActions = {
  fetchReports(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
      getState: () => AppState,
    ) => {
      dispatch(reportsActions.startLoading());
      const { startDate, endDate } = getState().reports;

      return request<Reports>('/reports', {
        method: Method.GET,
        params: {
          from: startDate,
          to: endDate,
        },
      })
        .then((reports) => {
          dispatch(reportsActions.receiveEntity(reports));
        })
        .finally(() => {
          dispatch(reportsActions.finishLoading());
        });
    };
  },
  receiveEntity(reports: Reports): ReportsAction {
    return {
      type: 'Reports.ReceiveEntity',
      payload: {
        reports,
      },
    };
  },
  changeStartDate(startDate: string | null): ReportsAction {
    return {
      type: 'Reports.ChangeStartDate',
      payload: {
        startDate,
      },
    };
  },
  changeEndDate(endDate: string | null): ReportsAction {
    return {
      type: 'Reports.ChangeEndDate',
      payload: {
        endDate,
      },
    };
  },
  startLoading(): ReportsAction {
    return {
      type: 'Reports.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): ReportsAction {
    return {
      type: 'Reports.FinishLoading',
      payload: undefined,
    };
  },
};
