import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { reportsActions } from '../actions';

function useReportsManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isLoading,
  } = useSelector((state: AppState) => ({
    entity: state.reports.entity,
    isLoading: state.reports.isLoading,
  }));

  const fetchReports = useCallback(
    () => {
      return dispatch(reportsActions.fetchReports());
    },
    [dispatch],
  );

  return {
    isLoading,
    fetchReports,
    reports: entity,
  };
}

export default useReportsManagement;
