import Action from '../../../shared/types/Action';
import ThunkAction from '../../../shared/types/ThunkAction';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import User from '../interfaces/User';
import request from '../../../shared/utilities/request';
import Method from '../../../shared/utilities/request/Method';
import RegistrationData from '../interfaces/RegistrationData';

export type UsersListAction =
  | Action<
    'Users.List.ReceiveEntities',
    {
      users: User[],
    }
  >
  | Action<
    'Users.List.ReceiveEntity',
    {
      user: User,
    }
  >
  | Action<
    'Users.List.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<
    'Users.List.ModifyEntity',
    {
      id: string,
      user: User,
    }
  >
  | Action<'Users.List.StartLoading'>
  | Action<'Users.List.FinishLoading'>;

export const usersListActions = {
  fetchUsers(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(usersListActions.startLoading());
      return request<User[]>('/users')
        .then((users) => {
          dispatch(usersListActions.receiveEntities(users));
        })
        .finally(() => {
          dispatch(usersListActions.finishLoading());
        });
    };
  },
  postUser(registrationData: RegistrationData): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(usersListActions.startLoading());
      return request<User>('/auth/register', {
        method: Method.POST,
        body: registrationData,
      })
        .then((user) => {
          dispatch(usersListActions.receiveEntity(user));
        })
        .finally(() => {
          dispatch(usersListActions.finishLoading());
        });
    };
  },
  patchUser(id: string, user: User): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(usersListActions.startLoading());
      return request<User>(`/users/${id}`, {
        method: Method.PATCH,
        body: user,
      })
        .then((patchedUser) => {
          dispatch(usersListActions.modifyEntity(id, patchedUser));
        })
        .finally(() => {
          dispatch(usersListActions.finishLoading());
        });
    };
  },
  receiveEntity(user: User): UsersListAction {
    return {
      type: 'Users.List.ReceiveEntity',
      payload: {
        user,
      },
    };
  },
  removeEntity(id: string): UsersListAction {
    return {
      type: 'Users.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, user: User): UsersListAction {
    return {
      type: 'Users.List.ModifyEntity',
      payload: {
        user,
        id,
      },
    };
  },
  receiveEntities(users: User[]): UsersListAction {
    return {
      type: 'Users.List.ReceiveEntities',
      payload: {
        users,
      },
    };
  },
  startLoading(): UsersListAction {
    return {
      type: 'Users.List.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): UsersListAction {
    return {
      type: 'Users.List.FinishLoading',
      payload: undefined,
    };
  },
};
