import React from 'react';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import useVoivodeshipsLoading from './hooks/useVoivodeshipsLoading';
import useVoivodeshipsManagement from './hooks/useVoivodeshipsManagement';
import useVoivodeshipModalManagement from './hooks/useVoivodeshipsModalManagement';
import VoivodeshipModal from './VoivodeshipModal';

const Voivodeships = () => {
  useVoivodeshipsLoading();
  const { isLoading, voivodeships, deleteVoivodeship } = useVoivodeshipsManagement();
  const { handleEdit, handleOpen } = useVoivodeshipModalManagement();
  return (
    <Loader isLoading={isLoading}>
      <VoivodeshipModal/>
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteVoivodeship}
        objects={voivodeships}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default Voivodeships;
