import FieldOfStudy from '../interfaces/FieldOfStudy';

export interface ModalState {
  isOpened: boolean;
  entity?: FieldOfStudy;
}

export const initialModalState: ModalState = {
  isOpened: false,
};
