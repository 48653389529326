import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import useStyles from './useStyles';

interface Props<ObjectType> {
  object: ObjectType;
  properties: {
    key: keyof ObjectType,
    title: string,
  }[];
  onEdit: () => void;
  onRemove?: () => void;
}

function Row<ObjectType extends { _id: string }>({ object, properties, onEdit, onRemove }: Props<ObjectType>) {
  const classes = useStyles();
  return (
    <TableRow >
      {
        properties.map(property => (
          <TableCell key={String(property.key)}>{object[property.key]}</TableCell>
        ))
      }
      <TableCell align="right" className={classes.toolsCell}>
        {
          onRemove && (
            <IconButton onClick={onRemove}>
              <Delete color="error"/>
            </IconButton>
          )
        }
        <IconButton onClick={onEdit}>
          <Edit color="primary"/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default Row;
