import React from 'react';
import useDepartmentsManagement from './hooks/useDepartmentsManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import DepartmentModal from './Modal';
import useDepartmentsModalManagement from './hooks/useDepartmentsModalManagement';

const Departments = () => {
  const { isLoading, departments, deleteDepartment } = useDepartmentsManagement();
  const { handleOpen, handleEdit } = useDepartmentsModalManagement();

  return (
    <Loader isLoading={isLoading}>
      <DepartmentModal />
      <List
        objects={departments}
        onRemove={deleteDepartment}
        onEdit={handleEdit}
        onAddButtonClick={handleOpen}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
        title="Wydziały"
      />
    </Loader>
  );
};

export default Departments;
