import FieldOfStudyType from '../interfaces/FieldOfStudyType';

export interface ListState {
  entities: FieldOfStudyType[];
  isLoading: boolean;
}

export const initialListState: ListState = {
  entities: [],
  isLoading: false,
};
