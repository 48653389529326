import User from '../interfaces/User';

export interface ModalsState {
  isCreateModalOpened: boolean;
  isEditModalOpened: boolean;
  entity?: User;
}

export const initialModalsState: ModalsState = {
  isCreateModalOpened: false,
  isEditModalOpened: false,
};
