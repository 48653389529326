import { initialState, ReportsState } from './state';
import { ReportsAction } from './actions';

const authenticationReducer = (
  state: ReportsState = initialState,
  action: ReportsAction,
) => {
  switch (action.type) {
    case 'Reports.ReceiveEntity':
      const { reports } = action.payload;
      return {
        ...state,
        entity: reports,
      };
    case 'Reports.ChangeStartDate': {
      const { startDate } = action.payload;
      return {
        ...state,
        startDate,
      };
    }
    case 'Reports.ChangeEndDate': {
      const { endDate } = action.payload;
      return {
        ...state,
        endDate,
      };
    }
    case 'Reports.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Reports.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default authenticationReducer;
