import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import Subject from './interfaces/Subject';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';

export type SubjectsAction =
  | Action<
    'Subjects.ReceiveEntities',
    {
      subjects: Subject[],
    }
  >
  | Action<
  'Subjects.ReceiveEntity',
  {
    subject: Subject;
  }
  >
  | Action<
    'Subjects.ModifyEntity',
    {
      id: string,
      subject: Subject;
    }
  >
  | Action<
    'Subjects.RemoveEntity',
    {
      id: string;
    }
  >
  | Action<
    'Subjects.OpenEntityModal',
    {
      subject?: Subject;
    }
  >
  | Action<'Subjects.CloseEntityModal'>
  | Action<'Subjects.StartLoading'>
  | Action<'Subjects.FinishLoading'>;

export const subjectsActions = {
  fetchSubjects(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectsActions.startLoading());
      return request<Subject[]>('/subjects')
        .then((subjects) => {
          dispatch(subjectsActions.receiveEntities(subjects));
        })
        .finally(() => {
          dispatch(subjectsActions.finishLoading());
        });
    };
  },
  postSubject(subject: Subject): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectsActions.startLoading());
      return request<Subject>('/subjects', {
        method: Method.POST,
        body: subject,
      })
        .then((receivedSubject) => {
          dispatch(subjectsActions.receiveEntity(receivedSubject));
        })
        .finally(() => {
          dispatch(subjectsActions.finishLoading());
        });
    };
  },
  editSubject(id: string, subject: Subject): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectsActions.startLoading());
      return request<Subject>(`/subjects/${id}`, {
        method: Method.PUT,
        body: subject,
      })
        .then((receivedSubject) => {
          dispatch(subjectsActions.modifyEntity(receivedSubject._id, receivedSubject));
        })
        .finally(() => {
          dispatch(subjectsActions.finishLoading());
        });
    };
  },
  deleteSubject(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(subjectsActions.startLoading());
      return request<Subject[]>(`/subjects/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(subjectsActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(subjectsActions.finishLoading());
        });
    };
  },
  receiveEntities(subjects: Subject[]): SubjectsAction {
    return {
      type: 'Subjects.ReceiveEntities',
      payload: {
        subjects,
      },
    };
  },
  receiveEntity(subject: Subject): SubjectsAction {
    return {
      type: 'Subjects.ReceiveEntity',
      payload: {
        subject,
      },
    };
  },
  modifyEntity(id: string, subject: Subject): SubjectsAction {
    return {
      type: 'Subjects.ModifyEntity',
      payload: {
        id,
        subject,
      },
    };
  },
  removeEntity(id: string): SubjectsAction {
    return {
      type: 'Subjects.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  openEntityModal(subject?: Subject): SubjectsAction {
    return {
      type: 'Subjects.OpenEntityModal',
      payload: {
        subject,
      },
    };
  },
  closeEntityModal(): SubjectsAction {
    return {
      type: 'Subjects.CloseEntityModal',
      payload: undefined,
    };
  },
  startLoading(): SubjectsAction {
    return {
      type: 'Subjects.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): SubjectsAction {
    return {
      type: 'Subjects.FinishLoading',
      payload: undefined,
    };
  },
};
