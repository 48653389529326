import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { fieldsOfStudyListActions } from '../actions/list';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { fieldsOfStudyModalActions } from '../actions/modal';
import FieldOfStudyForm from '../types/fieldOfStudyForm';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useFieldsOfStudyListManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    calculations,
    thresholds,
  } = useSelector((state: AppState) => ({
    entities: state.fieldsOfStudy.list.entities,
    calculations: state.calculations.list.entities,
    thresholds: state.thresholds.list.entities,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const addFieldOfStudy = useCallback(
    (fieldOfStudy: FieldOfStudyForm) => {
      dispatch(fieldsOfStudyListActions.receiveEntity({
        ...fieldOfStudy,
        thresholds,
        aghCalculation: fieldOfStudy.isAgh ? fieldOfStudy.aghCalculation : undefined,
        calculations: !fieldOfStudy.isAgh ? calculations : [],
      }));
      dispatch(fieldsOfStudyModalActions.close());
    },
    [dispatch, calculations, thresholds],
  );

  const editFieldOfStudy = useCallback(
    (id: string, fieldOfStudy: FieldOfStudyForm) => {
      dispatch(fieldsOfStudyListActions.modifyEntity(id, {
        ...fieldOfStudy,
        thresholds,
        aghCalculation: fieldOfStudy.isAgh ? fieldOfStudy.aghCalculation : undefined,
        calculations: !fieldOfStudy.isAgh ? calculations : [],
      }));
      dispatch(fieldsOfStudyModalActions.close());
    },
    [dispatch, calculations, thresholds],
  );

  const removeFieldOfStudy = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(fieldsOfStudyListActions.removeEntity(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    addFieldOfStudy,
    editFieldOfStudy,
    removeFieldOfStudy,
    fieldsOfStudy: entities,
  };
}

export default useFieldsOfStudyListManagement;
