import React from 'react';
import List from '../../shared/components/List';
import FieldOfStudyModal from './Modal';
import useFieldsOfStudyModalManagement from './hooks/useFieldsOfStudyModalManagement';
import useFieldsOfStudyListManagement from './hooks/useFieldsOfStudyListManagement';
import useFieldOfStudyTypesLoading from '../FieldOfStudyTypes/hooks/useFieldOfStudyTypesLoading';

const FieldsOfStudy = () => {
  const { handleOpen, handleEdit } = useFieldsOfStudyModalManagement();
  const { fieldsOfStudy, removeFieldOfStudy } = useFieldsOfStudyListManagement();
  useFieldOfStudyTypesLoading();
  return (
    <div>
      <FieldOfStudyModal />
      <List
        objects={fieldsOfStudy}
        onRemove={removeFieldOfStudy}
        onEdit={handleEdit}
        onAddButtonClick={handleOpen}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
        title="Kierunki"
      />
    </div>
  );
};

export default FieldsOfStudy;
