import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { voivodeshipsActions } from '../actions';
import Voivodeship from '../interfaces/Voivodeship';

function useVoivodeshipModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entity,
    isOpened,
  } = useSelector((state: AppState) => ({
    entity: state.voivodeships.entityModal.entity,
    isOpened: state.voivodeships.entityModal.isOpened,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      return dispatch(voivodeshipsActions.openEntityModal());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (voivodeship?: Voivodeship) => (
      () => {
        return dispatch(voivodeshipsActions.openEntityModal(voivodeship));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(voivodeshipsActions.closeEntityModal());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        name: entity.name,
      } : {
        name: '',
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    handleOpen,
    handleEdit,
    handleClose,
    isEditing,
    initialValues,
    voivodeship: entity,
  };
}

export default useVoivodeshipModalManagement;
