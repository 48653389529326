import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import University from '../../Universities/interfaces/University';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useCreateModalManagement from '../Modals/Create/useCreateModalManagement';
import { universitiesActions } from '../actions';
import useEditModalManagement from '../Modals/Edit/useEditModalManagement';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useUniversitiesManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.universities.entities,
    isLoading: state.universities.isLoading,
  }));
  const { handleOpen } = useDeletePopupManagement();
  const { handleClose } = useCreateModalManagement();
  const { handleClose: handleEditModalClose } = useEditModalManagement();

  const fetchUniversities = useCallback(
    () => {
      return dispatch(universitiesActions.fetchUniversities());
    },
    [dispatch],
  );

  const postUniversity = useCallback(
    (university: University) => {
      return dispatch(universitiesActions.postUniversity(university))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editUniversity = useCallback(
    (id: string, university: University) => {
      return dispatch(universitiesActions.editUniversity(id, university))
        .finally(() => {
          handleEditModalClose();
        });
    },
    [dispatch, handleEditModalClose],
  );

  const deleteUniversity = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(universitiesActions.deleteUniversity(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchUniversities,
    postUniversity,
    editUniversity,
    deleteUniversity,
    universities: entities,
  };
}

export default useUniversitiesManagement;
