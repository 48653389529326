import React from 'react';
import useCitiesLoading from './hooks/useCitiesLoading';
import useCitiesManagement from './hooks/useCitiesManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import CityModal from './CityModal';
import useCityModalManagement from './hooks/useCityModalManagement';
import useVoivodeshipsLoading from '../Voivodeships/hooks/useVoivodeshipsLoading';

const Cities = () => {
  useCitiesLoading();
  useVoivodeshipsLoading();
  const { isLoading, cities, deleteCity } = useCitiesManagement();
  const { handleOpen, handleEdit } = useCityModalManagement();
  return (
    <Loader isLoading={isLoading}>
      <CityModal/>
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteCity}
        objects={cities}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default Cities;
