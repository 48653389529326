import User from '../interfaces/User';

export interface ListState {
  entities: User[];
  isLoading: boolean;
}

export const initialListState: ListState = {
  entities: [],
  isLoading: false,
};
