import useReportsManagement from '../hooks/useReportsManagement';
import { useMemo } from 'react';
import useUniversitiesManagement from '../../Universities/hooks/useUniversitiesManagement';

export function useUniversitiesChartsData() {
  const { universities } = useUniversitiesManagement();
  const { reports } = useReportsManagement();

  const universitiesChartsData = useMemo(
    () => {
      const universitiesMap = new Map(universities.map(university => [university._id, university]));
      return reports?.universities.map(universityReport => ({
        name: universitiesMap.get(universityReport._id)?.name,
        total: universityReport.total,
      }));
    },
    [universities, reports],
  );

  return {
    universitiesChartsData,
  };
}
