import React, { FunctionComponent } from 'react';
import Method from './Method';

interface Props {
  method: Method;
  url: string;
  status: number;
  statusText: string;
  message: string;
}

const ErrorTemplate: FunctionComponent<Props> = ({
  method,
  url,
  status,
  statusText,
  message,
}) => (
  <div>
    <p><strong>Method:</strong> {method}</p>
    <p><strong>Url:</strong> {url} </p>
    <p><strong>Status:</strong> {status} - {statusText} </p>
    <p><strong>Message:</strong> {message} </p>
  </div>
);

export default ErrorTemplate;
