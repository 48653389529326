import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';
import UniversityType from './interfaces/UniversityType';

export type UniversityTypesAction =
  | Action<
    'UniversityTypes.ReceiveEntities',
    {
      universityTypes: UniversityType[],
    }
  >
  | Action<
    'UniversityTypes.ReceiveEntity',
    {
      universityType: UniversityType,
    }
  >
  | Action<
    'UniversityTypes.ModifyEntity',
    {
      universityType: UniversityType,
      id: string,
    }
  >
  | Action<
    'UniversityTypes.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<'UniversityTypes.OpenEntityModal', {
    universityType?: UniversityType,
  }>
  | Action<'UniversityTypes.CloseEntityModal'>
  | Action<'UniversityTypes.StartLoading'>
  | Action<'UniversityTypes.FinishLoading'>;

export const universityTypesActions = {
  fetchUniversityTypes(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universityTypesActions.startLoading());
      return request<UniversityType[]>('/university-types')
        .then((universityTypes) => {
          dispatch(universityTypesActions.receiveEntities(universityTypes));
        })
        .finally(() => {
          dispatch(universityTypesActions.finishLoading());
        });
    };
  },
  postUniversityType(universityType: UniversityType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universityTypesActions.startLoading());
      return request<UniversityType>('/university-types', {
        method: Method.POST,
        body: universityType,
      })
        .then((receivedUniversityType) => {
          dispatch(universityTypesActions.receiveEntity(receivedUniversityType));
        })
        .finally(() => {
          dispatch(universityTypesActions.finishLoading());
        });
    };
  },
  editUniversityType(id: string, universityType: UniversityType): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universityTypesActions.startLoading());
      return request<UniversityType>(`/university-types/${id}`, {
        method: Method.PUT,
        body: universityType,
      })
        .then((receivedUniversityType) => {
          dispatch(universityTypesActions.modifyEntity(receivedUniversityType._id, receivedUniversityType));
        })
        .finally(() => {
          dispatch(universityTypesActions.finishLoading());
        });
    };
  },
  deleteUniversityType(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(universityTypesActions.startLoading());
      return request<UniversityType>(`/university-types/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(universityTypesActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(universityTypesActions.finishLoading());
        });
    };
  },
  removeEntity(id: string): UniversityTypesAction {
    return {
      type: 'UniversityTypes.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, universityType: UniversityType): UniversityTypesAction {
    return {
      type: 'UniversityTypes.ModifyEntity',
      payload: {
        universityType,
        id,
      },
    };
  },
  receiveEntity(universityType: UniversityType): UniversityTypesAction {
    return {
      type: 'UniversityTypes.ReceiveEntity',
      payload: {
        universityType,
      },
    };
  },
  openEntityModal(universityType?: UniversityType): UniversityTypesAction {
    return {
      type: 'UniversityTypes.OpenEntityModal',
      payload: {
        universityType,
      },
    };
  },
  closeEntityModal(): UniversityTypesAction {
    return {
      type: 'UniversityTypes.CloseEntityModal',
      payload: undefined,
    };
  },
  receiveEntities(universityTypes: UniversityType[]): UniversityTypesAction {
    return {
      type: 'UniversityTypes.ReceiveEntities',
      payload: {
        universityTypes,
      },
    };
  },
  startLoading(): UniversityTypesAction {
    return {
      type: 'UniversityTypes.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): UniversityTypesAction {
    return {
      type: 'UniversityTypes.FinishLoading',
      payload: undefined,
    };
  },
};
