import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { subjectsActions } from '../actions';
import { useCallback } from 'react';
import Subject from '../interfaces/Subject';
import useSubjectModalManagement from './useSubjectModalManagement';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useSubjectsManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { handleClose } = useSubjectModalManagement();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.subjects.entities,
    isLoading: state.subjects.isLoading,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const fetchSubjects = useCallback(
    () => {
      return dispatch(subjectsActions.fetchSubjects());
    },
    [dispatch],
    );

  const postSubject = useCallback(
    (subject: Subject) => {
      return dispatch(subjectsActions.postSubject(subject))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editSubject = useCallback(
    (id: string, subject: Subject) => {
      return dispatch(subjectsActions.editSubject(id, subject))
        .finally(() => {
          handleClose();
        });

    },
    [dispatch, handleClose],
  );

  const deleteSubject = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(subjectsActions.deleteSubject(id)));
    },
    [dispatch, handleOpen],
  );

  const getSubjectById = useCallback(
    (id: string) => {
      return entities.find(entity => entity._id === id);
    },
    [entities],
  );

  return {
    isLoading,
    fetchSubjects,
    postSubject,
    editSubject,
    deleteSubject,
    getSubjectById,
    subjects: entities,
  };
}

export default useSubjectsManagement;
