import Action from '../../../shared/types/Action';
import FieldOfStudyType from '../interfaces/FieldOfStudyType';

export type FieldOfStudyTypeModalAction =
  | Action<'FieldOfStudyTypes.Modal.Open', {
    fieldOfStudyType?: FieldOfStudyType,
  }>
  | Action<'FieldOfStudyTypes.Modal.Close'>;

export const fieldOfStudyTypeModalActions = {
  open(fieldOfStudyType?: FieldOfStudyType): FieldOfStudyTypeModalAction {
    return {
      type: 'FieldOfStudyTypes.Modal.Open',
      payload: {
        fieldOfStudyType,
      },
    };
  },
  close(): FieldOfStudyTypeModalAction {
    return {
      type: 'FieldOfStudyTypes.Modal.Close',
      payload: undefined,
    };
  },
};
