import React from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from './styles.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import getDefaultLabel from '../../../utilities/getDefaultLabel';
import Option from '../../../types/Option';

interface Props<ValueType> {
  name: string;
  label?: string;
  dataTestId?: string;
  options: Option<ValueType>[];
}

function SelectInput<ValueType>({
  name,
  options,
  label,
} : Props<ValueType>) {
  return (
    <Grid item={true}>
      <Field
        name={name}
        id={name}
      >
        {({ field, form, meta }: FieldProps) => (
          <FormControl error={Boolean(meta.error)} className={styles.root}>
            <Autocomplete
              id={name}
              options={options}
              getOptionLabel={(value: any) => {
                if (value?.label) {
                  return value.label;
                }  if (value) {
                  const option = options.find(foundOption => foundOption.value === value);
                  if (option) {
                    return option.label;
                  }
                }
                return '';
              }}
              value={field.value}
              onChange={onChange(form, name)}
              renderInput={params => (
                <TextField
                  {...params}
                  name={name}
                  label={label || getDefaultLabel(name)}
                  variant="outlined"
                  fullWidth={true}
                />
              )}
            />
            <FormHelperText
              id={`component-error-${name}`}
              className={meta.error ? '' : styles.helperWithoutError}
            >
              {meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Grid>
  );
}

function onChange<ValueType>(form: FormikProps<any>, name: string) {
  return (e: any, option: Option<ValueType> | null) => {
    form.setFieldValue(name, option ? option.value : undefined);
  };
}

export default SelectInput;
