import React from 'react';
import Universities from '../../../../modules/Universities';
import UniversityTypes from '../../../../modules/UniversityTypes';
import Cities from '../../../../modules/Cities';
import Voivodeships from '../../../../modules/Voivodeships';
import Subjects from '../../../../modules/Subjects';
import Departments from '../../../../modules/Departments';
import SubjectTypes from '../../../../modules/SubjectTypes';
import LogInForm from '../../../../modules/LogInForm';
import useAuthenticationManagement from '../../../../modules/Authentication/hooks/useAuthenticationManagement';
import ProtectedRoute from './ProtectedRoute';
import Users from '../../../../modules/Users';
import FieldOfStudyTypes from '../../../../modules/FieldOfStudyTypes';
import Reports from '../../../../modules/Reports';

const Routes = () => {
  const { isLoggedIn } = useAuthenticationManagement();
  return (
    <>
      <ProtectedRoute
        path="/universities"
        component={Universities}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/university-types"
        component={UniversityTypes}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/cities"
        component={Cities}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/voivodeships"
        component={Voivodeships}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/subjects"
        component={Subjects}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/subject-types"
        component={SubjectTypes}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/departments"
        component={Departments}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/users"
        component={Users}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/login"
        component={LogInForm}
        exact={true}
        isAccessible={!isLoggedIn}
        redirectToWhenInaccessible="/universities"
      />
      <ProtectedRoute
        path="/"
        component={() => <></>}
        exact={true}
        isAccessible={false}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/field-of-study-types"
        component={FieldOfStudyTypes}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
      <ProtectedRoute
        path="/reports"
        component={Reports}
        exact={true}
        isAccessible={isLoggedIn}
        redirectToWhenInaccessible="/login"
      />
    </>
  );
};

export default Routes;
