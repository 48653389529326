import { ListState, initialListState } from '../state/list';
import { CalculationsListAction } from '../actions/list';

const departmentsListReducer = (
  state: ListState = initialListState,
  action: CalculationsListAction,
) => {
  switch (action.type) {
    case 'Calculations.List.ReceiveEntities':
      const { calculations } = action.payload;
      return {
        ...state,
        entities: calculations,
      };
    case 'Calculations.List.ReceiveEntity': {
      const { calculation } = action.payload;
      const entities = [
        ...state.entities,
        calculation,
      ];
      return {
        ...state,
        entities,
      };
    }
    case 'Calculations.List.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Calculations.List.ModifyEntity': {
      const { calculation, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = calculation;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Calculations.List.Clear': {
      return {
        ...initialListState,
      };
    }
    default:
      return state;
  }
};

export default departmentsListReducer;
