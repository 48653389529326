import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import MaterialToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from '../useStyles';
import usePageTitle from '../usePageTitle';
import UserMenu from './UserMenu';

interface Props {
  open: boolean;
  handleDrawerOpen: () => void;
}

const Toolbar: FunctionComponent<Props> = ({
  open,
  handleDrawerOpen,
}) => {
  const classes = useStyles();
  const pageTitle = usePageTitle();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <MaterialToolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap={true}>
          {pageTitle}
        </Typography>
        <UserMenu />
      </MaterialToolbar>
    </AppBar>
  );
};

export default Toolbar;
