import Action from '../../../shared/types/Action';
import FieldOfStudy from '../interfaces/FieldOfStudy';
import { v4 as uuidv4 } from 'uuid';

export type FieldsOfStudyListAction =
  | Action<
    'FieldsOfStudy.List.ReceiveEntities',
    {
      fieldsOfStudy: FieldOfStudy[],
    }
  >
  | Action<
    'FieldsOfStudy.List.ReceiveEntity',
    {
      fieldOfStudy: FieldOfStudy,
    }
  >
  | Action<
    'FieldsOfStudy.List.RemoveEntity',
    {
      id: string,
    }
  >
  | Action<
    'FieldsOfStudy.List.ModifyEntity',
    {
      id: string,
      fieldOfStudy: FieldOfStudy,
    }
  >
  | Action<'FieldsOfStudy.List.Clear'>;

export const fieldsOfStudyListActions = {
  receiveEntity(fieldOfStudy: FieldOfStudy): FieldsOfStudyListAction {
    fieldOfStudy._id = uuidv4();
    return {
      type: 'FieldsOfStudy.List.ReceiveEntity',
      payload: {
        fieldOfStudy,
      },
    };
  },
  removeEntity(id: string): FieldsOfStudyListAction {
    return {
      type: 'FieldsOfStudy.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, fieldOfStudy: FieldOfStudy): FieldsOfStudyListAction {
    return {
      type: 'FieldsOfStudy.List.ModifyEntity',
      payload: {
        id,
        fieldOfStudy: {
          _id: id,
          ...fieldOfStudy,
        },
      },
    };
  },
  receiveEntities(fieldsOfStudy: FieldOfStudy[]): FieldsOfStudyListAction {
    return {
      type: 'FieldsOfStudy.List.ReceiveEntities',
      payload: {
        fieldsOfStudy,
      },
    };
  },
  clear(): FieldsOfStudyListAction {
    return {
      type: 'FieldsOfStudy.List.Clear',
      payload: undefined,
    };
  },
};
