import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import City from './interfaces/City';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';

export type CitiesAction =
  | Action<
  'Cities.ReceiveEntities',
  {
    cities: City[],
  }
  >
  | Action<
  'Cities.ReceiveEntity',
  {
    city: City;
  }
  >
  | Action<
  'Cities.ModifyEntity',
  {
    id: string,
    city: City;
  }
  >
  | Action<
  'Cities.RemoveEntity',
  {
    id: string;
  }
  >
  | Action<
  'Cities.OpenEntityModal',
  {
    city?: City;
  }
  >
  | Action<'Cities.CloseEntityModal'>
  | Action<'Cities.StartLoading'>
  | Action<'Cities.FinishLoading'>;

export const citiesActions = {
  fetchCities(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(citiesActions.startLoading());
      return request<City[]>('/cities')
        .then((cities) => {
          dispatch(citiesActions.receiveEntities(cities));
        })
        .finally(() => {
          dispatch(citiesActions.finishLoading());
        });
    };
  },
  postCity(city: City): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(citiesActions.startLoading());
      return request<City>('/cities', {
        method: Method.POST,
        body: city,
      })
        .then((receivedSubject) => {
          dispatch(citiesActions.receiveEntity(receivedSubject));
        })
        .finally(() => {
          dispatch(citiesActions.finishLoading());
        });
    };
  },
  editCity(id: string, city: City): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(citiesActions.startLoading());
      return request<City>(`/cities/${id}`, {
        method: Method.PUT,
        body: city,
      })
        .then((receivedSubject) => {
          dispatch(citiesActions.modifyEntity(receivedSubject._id, receivedSubject));
        })
        .finally(() => {
          dispatch(citiesActions.finishLoading());
        });
    };
  },
  deleteCity(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(citiesActions.startLoading());
      return request<City[]>(`/cities/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(citiesActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(citiesActions.finishLoading());
        });
    };
  },
  receiveEntities(cities: City[]): CitiesAction {
    return {
      type: 'Cities.ReceiveEntities',
      payload: {
        cities,
      },
    };
  },
  receiveEntity(city: City): CitiesAction {
    return {
      type: 'Cities.ReceiveEntity',
      payload: {
        city,
      },
    };
  },
  modifyEntity(id: string, city: City): CitiesAction {
    return {
      type: 'Cities.ModifyEntity',
      payload: {
        id,
        city,
      },
    };
  },
  removeEntity(id: string): CitiesAction {
    return {
      type: 'Cities.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  openEntityModal(city?: City): CitiesAction {
    return {
      type: 'Cities.OpenEntityModal',
      payload: {
        city,
      },
    };
  },
  closeEntityModal(): CitiesAction {
    return {
      type: 'Cities.CloseEntityModal',
      payload: undefined,
    };
  },
  startLoading(): CitiesAction {
    return {
      type: 'Cities.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): CitiesAction {
    return {
      type: 'Cities.FinishLoading',
      payload: undefined,
    };
  },
};
