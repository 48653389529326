import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import UniversityType from '../../UniversityTypes/interfaces/UniversityType';
import { universityTypesActions } from '../actions';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import useUniversityTypeModalManagement from './useUniversityTypeModalManagement';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useUniversityTypesManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
    isLoading,
  } = useSelector((state: AppState) => ({
    entities: state.universityTypes.entities,
    isLoading: state.universityTypes.isLoading,
  }));
  const { handleClose } = useUniversityTypeModalManagement();
  const { handleOpen } = useDeletePopupManagement();

  const fetchUniversityTypes = useCallback(
    () => {
      return dispatch(universityTypesActions.fetchUniversityTypes());
    },
    [dispatch])
  ;

  const postUniversityType = useCallback(
    (universityType: UniversityType) => {
      return dispatch(universityTypesActions.postUniversityType(universityType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const editUniversityType = useCallback(
    (id: string, universityType: UniversityType) => {
      return dispatch(universityTypesActions.editUniversityType(id, universityType))
        .finally(() => {
          handleClose();
        });
    },
    [dispatch, handleClose],
  );

  const deleteUniversityType = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(universityTypesActions.deleteUniversityType(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    isLoading,
    fetchUniversityTypes,
    postUniversityType,
    editUniversityType,
    deleteUniversityType,
    universityTypes: entities,
  };
}

export default useUniversityTypesManagement;
