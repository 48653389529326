import React from 'react';
import List from '../../shared/components/List';
import ThresholdModal from './Modal';
import useThresholdsModalManagement from './hooks/useThresholdsModalManagement';
import useThresholdsListManagement from './hooks/useThresholdsListManagement';

const Thresholds = () => {
  const { handleOpen, handleEdit } = useThresholdsModalManagement();
  const { removeThreshold, thresholds } = useThresholdsListManagement();
  return (
    <div>
      <ThresholdModal />
      <List
        objects={thresholds}
        onRemove={removeThreshold}
        onEdit={handleEdit}
        onAddButtonClick={handleOpen}
        properties={[
          {
            key: 'year',
            title: 'Rok',
          },
          {
            key: 'value',
            title: 'Próg',
          },
        ]}
        title="Progi"
      />
    </div>
  );
};

export default Thresholds;
