import React from 'react';
import useReportsLoading from './hooks/useReportsLoading';
import useFieldOfStudyTypesLoading from '../FieldOfStudyTypes/hooks/useFieldOfStudyTypesLoading';
import useUniversitiesLoading from '../Universities/hooks/useUniversitiesLoading';
import useCitiesLoading from '../Cities/hooks/useCitiesLoading';
import FieldOfStudyTypesCharts from './FieldOfStudyTypesCharts';
import CitiesCharts from './CitiesCharts';
import UniversitiesCharts from './UniversitiesCharts';
import DateRange from './DateRange';

const Reports = () => {
  useReportsLoading();
  useFieldOfStudyTypesLoading();
  useUniversitiesLoading();
  useCitiesLoading();

  return (
    <div>
      <DateRange />
      <CitiesCharts />
      <FieldOfStudyTypesCharts />
      <UniversitiesCharts />
    </div>
  );
};

export default Reports;
