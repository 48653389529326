import React from 'react';
import useSubjectsLoading from './hooks/useSubjectsLoading';
import useSubjectsManagement from './hooks/useSubjectsManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import SubjectModal from './SubjectModal';
import useSubjectModalManagement from './hooks/useSubjectModalManagement';
import useSubjectTypesLoading from '../SubjectTypes/hooks/useSubjectTypesLoading';

const Subjects = () => {
  useSubjectsLoading();
  useSubjectTypesLoading();
  const { isLoading, subjects, deleteSubject } = useSubjectsManagement();
  const { handleEdit, handleOpen } = useSubjectModalManagement();
  return (
    <Loader isLoading={isLoading}>
      <SubjectModal/>
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteSubject}
        objects={subjects}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default Subjects;
