import Action from '../../shared/types/Action';
import ThunkAction from '../../shared/types/ThunkAction';
import ThunkDispatch from '../../shared/types/ThunkDispatch';
import request from '../../shared/utilities/request';
import Method from '../../shared/utilities/request/Method';
import User from '../Users/interfaces/User';
import LogInData from './interfaces/LogInData';

export type AuthenticationAction =
  | Action<
    'Authentication.ReceiveUser',
    {
      user: User,
    }
  >
  | Action<'Authentication.RemoveUser'>
  | Action<'Authentication.StartLoading'>
  | Action<'Authentication.FinishLoading'>;

export const authenticationActions = {
  logIn(logInData: LogInData): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(authenticationActions.startLoading());
      return request<User>('/auth/log-in', { body: logInData, method: Method.POST })
        .then((user) => {
          dispatch(authenticationActions.receiveUser(user));
        })
        .finally(() => {
          dispatch(authenticationActions.finishLoading());
        });
    };
  },
  logOut(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(authenticationActions.startLoading());
      return request<User>('/auth/log-out', { method: Method.POST })
        .then((user) => {
          dispatch(authenticationActions.removeUser());
        })
        .finally(() => {
          dispatch(authenticationActions.finishLoading());
        });
    };
  },
  authenticate(): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(authenticationActions.startLoading());
      return request<User>('/auth', { method: Method.GET, hideErrors: true })
        .then((user) => {
          dispatch(authenticationActions.receiveUser(user));
        })
        .finally(() => {
          dispatch(authenticationActions.finishLoading());
        });
    };
  },
  receiveUser(user: User): AuthenticationAction {
    return {
      type: 'Authentication.ReceiveUser',
      payload: {
        user,
      },
    };
  },
  removeUser(): AuthenticationAction {
    return {
      type: 'Authentication.RemoveUser',
      payload: undefined,
    };
  },
  startLoading(): AuthenticationAction {
    return {
      type: 'Authentication.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): AuthenticationAction {
    return {
      type: 'Authentication.FinishLoading',
      payload: undefined,
    };
  },
};
