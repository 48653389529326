import ThunkDispatch from '../../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { useCallback, useMemo } from 'react';
import User from '../../interfaces/User';
import { usersModalsActions } from '../../actions/modals';

function useEditModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { isOpened, entity } = useSelector((state: AppState) => ({
    isOpened: state.users.modals.isEditModalOpened,
    entity: state.users.modals.entity,
  }));

  const handleOpen = useCallback(
    (user: User) => (
      () => {
        return dispatch(usersModalsActions.openEditModal(user));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(usersModalsActions.closeEditModal());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => ({
      name: entity?.name,
      email: entity?.email,
    }),
    [entity],
  );

  return {
    isOpened,
    handleOpen,
    handleClose,
    initialValues,
    user: entity,
  };

}

export default useEditModalManagement;
