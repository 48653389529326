import Action from '../../../shared/types/Action';
import ThunkAction from '../../../shared/types/ThunkAction';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import Department from '../interfaces/Department';
import request from '../../../shared/utilities/request';
import { AppState } from '../../../store/reducers';
import Method from '../../../shared/utilities/request/Method';
import FieldOfStudyToSend from '../../FieldsOfStudy/interfaces/FieldOfStudyToSend';

export type DepartmentsListAction =
  | Action<
  'Departments.List.ReceiveEntities',
  {
    departments: Department[],
  }
  >
  | Action<
  'Departments.List.ReceiveEntity',
  {
    department: Department,
  }
  >
  | Action<
  'Departments.List.RemoveEntity',
  {
    id: string,
  }
  >
  | Action<
  'Departments.List.ModifyEntity',
  {
    id: string,
    department: Department,
  }
  >
  | Action<'Departments.List.StartLoading'>
  | Action<'Departments.List.FinishLoading'>;

type DepartmentToSend = Omit<Department, 'fieldsOfStudy'> & {
  fieldsOfStudy: FieldOfStudyToSend[],
};

export const departmentsListActions = {
  fetchDepartments(universityId: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(departmentsListActions.startLoading());
      return request<Department[]>(`/departments?universityId=${universityId}`)
        .then((departments) => {
          dispatch(departmentsListActions.receiveEntities(departments));
        })
        .finally(() => {
          dispatch(departmentsListActions.finishLoading());
        });
    };
  },
  postDepartment(department: DepartmentToSend): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
      getState: () => AppState,
    ) => {
      const state = getState();
      const universityId = state.departments.modal.universityId;
      if (universityId) {
        dispatch(departmentsListActions.startLoading());
        return request<Department>('/departments', {
          method: Method.POST,
          body: {
            ...department,
            university: universityId,
          },
        })
          .then((postedDepartment) => {
            dispatch(departmentsListActions.receiveEntity(postedDepartment));
          })
          .finally(() => {
            dispatch(departmentsListActions.finishLoading());
          });
      }
    };
  },
  editDepartment(id: string, department: DepartmentToSend): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(departmentsListActions.startLoading());
      return request<Department>(`/departments/${id}`, {
        method: Method.PUT,
        body: department,
      })
        .then((editedDepartment) => {
          dispatch(departmentsListActions.modifyEntity(id, editedDepartment));
        })
        .finally(() => {
          dispatch(departmentsListActions.finishLoading());
        });
    };
  },
  deleteDepartment(id: string): ThunkAction {
    return async (
      dispatch: ThunkDispatch,
    ) => {
      dispatch(departmentsListActions.startLoading());
      return request(`/departments/${id}`, {
        method: Method.DELETE,
      })
        .then(() => {
          dispatch(departmentsListActions.removeEntity(id));
        })
        .finally(() => {
          dispatch(departmentsListActions.finishLoading());
        });
    };
  },
  receiveEntity(department: Department): DepartmentsListAction {
    return {
      type: 'Departments.List.ReceiveEntity',
      payload: {
        department,
      },
    };
  },
  removeEntity(id: string): DepartmentsListAction {
    return {
      type: 'Departments.List.RemoveEntity',
      payload: {
        id,
      },
    };
  },
  modifyEntity(id: string, department: Department): DepartmentsListAction {
    return {
      type: 'Departments.List.ModifyEntity',
      payload: {
        department,
        id,
      },
    };
  },
  receiveEntities(departments: Department[]): DepartmentsListAction {
    return {
      type: 'Departments.List.ReceiveEntities',
      payload: {
        departments,
      },
    };
  },
  startLoading(): DepartmentsListAction {
    return {
      type: 'Departments.List.StartLoading',
      payload: undefined,
    };
  },
  finishLoading(): DepartmentsListAction {
    return {
      type: 'Departments.List.FinishLoading',
      payload: undefined,
    };
  },
};
