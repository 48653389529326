import useFieldOfStudyTypesManagement from './useFieldOfStudyTypesManagement';
import { useEffect } from 'react';

function useFieldOfStudyTypesLoading() {
  const { fetchFieldOfStudyTypes } = useFieldOfStudyTypesManagement();
  useEffect(
    () => {
      fetchFieldOfStudyTypes();
    },
    [fetchFieldOfStudyTypes],
  );
}

export default useFieldOfStudyTypesLoading;
