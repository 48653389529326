import Action from '../../../shared/types/Action';
import Threshold from '../interfaces/Threshold';

export type ThresholdsModalAction =
  | Action<'Thresholds.Modal.Open', {
    threshold?: Threshold,
  }>
  | Action<'Thresholds.Modal.Close'>;

export const thresholdsModalActions = {
  open(threshold?: Threshold): ThresholdsModalAction {
    return {
      type: 'Thresholds.Modal.Open',
      payload: {
        threshold,
      },
    };
  },
  close(): ThresholdsModalAction {
    return {
      type: 'Thresholds.Modal.Close',
      payload: undefined,
    };
  },
};
