import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { calculationsListActions } from '../actions/list';
import { useCallback } from 'react';
import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { calculationsModalActions } from '../actions/modal';
import Calculation from '../interfaces/Calculation';
import CalculationInForm from '../interfaces/CalculationInForm';
import Variant from '../interfaces/Variant';
import useDeletePopupManagement from '../../DeletePopup/hooks/useDeletePopupManagement';

function useCalculationsListManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    entities,
  } = useSelector((state: AppState) => ({
    entities: state.calculations.list.entities,
  }));
  const { handleOpen } = useDeletePopupManagement();

  const addCalculation = useCallback(
    (calculation: CalculationInForm) => {
      dispatch(calculationsListActions.receiveEntity(mapCalculation(calculation)));
      dispatch(calculationsModalActions.close());
    },
    [dispatch],
  );

  const editCalculation = useCallback(
    (id: string, calculation: CalculationInForm) => {
      dispatch(calculationsListActions.modifyEntity(id, mapCalculation(calculation)));
      dispatch(calculationsModalActions.close());
    },
    [dispatch],
  );

  const removeCalculation = useCallback(
    (id: string) => {
      handleOpen(() => dispatch(calculationsListActions.removeEntity(id)));
    },
    [dispatch, handleOpen],
  );

  return {
    addCalculation,
    editCalculation,
    removeCalculation,
    calculations: entities,
  };
}

function mapCalculation(calculationInForm: CalculationInForm): Calculation {
  const calculation = {
    ...calculationInForm,
    variants: [] as Variant[],
  };
  if (calculationInForm.subjects?.length) {
    calculation.variants = calculationInForm.subjects.map(subject => ({
      subject,
      ...(calculationInForm.subjectsMultipliers[subject] || {}),
    }));
  }
  if (calculationInForm.subjectTypes?.length) {
    calculation.variants = [
      ...calculation.variants,
      ...calculationInForm.subjectTypes.map(subjectType => ({
        subjectType,
        ...(calculationInForm.subjectsMultipliers[subjectType] || {}),
      })),
    ];
  }
  delete calculation.subjects;
  delete calculation.subjectTypes;
  return calculation;
}

export default useCalculationsListManagement;
