import { ModalState, initialModalState } from '../state/modal';
import { CalculationsModalAction } from '../actions/modal';

const calculationsModalReducer = (
  state: ModalState = initialModalState,
  action: CalculationsModalAction,
) => {
  switch (action.type) {
    case 'Calculations.Modal.Open': {
      const { calculation } = action.payload;
      return {
        ...state,
        entity: calculation,
        isOpened: true,
      };
    }
    case 'Calculations.Modal.Close':
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};

export default calculationsModalReducer;
