import React from 'react';
import useUniversityTypesLoading from './hooks/useUniversityTypesLoading';
import useUniversityTypesManagement from './hooks/useUniversityTypesManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import UniversityTypeModal from './UniversityTypeModal';
import useUniversityTypeModalManagement from './hooks/useUniversityTypeModalManagement';

const UniversityTypes = () => {
  useUniversityTypesLoading();
  const { isLoading, universityTypes, deleteUniversityType } = useUniversityTypesManagement();
  const { handleOpen, handleEdit } = useUniversityTypeModalManagement();

  return (
    <Loader isLoading={isLoading}>
      <UniversityTypeModal />
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteUniversityType}
        objects={universityTypes}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default UniversityTypes;
