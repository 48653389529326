import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '400px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  logo: {
    width: '75px',
  },
});

export default useStyles;
