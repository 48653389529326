import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  toolsCell: {
    color: 'white',
    width: '130px',
  },
  tableHead: {
    backgroundColor: palette.primary.light,
  },
  tableCell: {
    color: 'white',
  },
}));
