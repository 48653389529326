import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import CalculationInForm from '../../interfaces/CalculationInForm';

function useCheckboxManagement() {
  const { setFieldValue } = useFormikContext<CalculationInForm>();
  const handleChange = useCallback(
    () => {
      setFieldValue('subjectsMultipliers', {});
      setFieldValue('subjectTypesMultipliers', {});
    },
    [setFieldValue],
  );
  return {
    handleChange,
  };
}

export default useCheckboxManagement;
