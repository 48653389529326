import University from './interfaces/University';

export interface EditModalState {
  isOpened: boolean;
  entity?: University;
}

export interface UniversitiesState {
  entities: University[];
  createModal: {
    isOpened: boolean;
  };
  editModal: EditModalState;
  isLoading: boolean;
}

export const editModalInitialState: EditModalState = {
  isOpened: false,
};

export const initialState: UniversitiesState = {
  entities: [],
  createModal: {
    isOpened: false,
  },
  editModal: {
    isOpened: false,
  },
  isLoading: false,
};
