import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import getDefaultLabel from '../../utilities/getDefaultLabel';
import styles from './styles.module.scss';

interface Props {
  type: string;
  name: string;
  value?: unknown;
  multiline?: boolean;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  dataTestId?: string;
}

const MaterialInput: FunctionComponent<Props> = ({
  type,
  value,
  onChange,
  onBlur,
  label,
  name,
  multiline = false,
  dataTestId,
}) => {
  return (
    <TextField
      data-testid={dataTestId}
      type={type}
      label={label || getDefaultLabel(name)}
      id={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      aria-describedby={`component-error-${name}`}
      multiline={multiline}
      rows={multiline ? 3 : 1}
      className={multiline ? styles.multiline : ''}
    />
  );
};

export default MaterialInput;
