import React from 'react';
import EntityModal from '../../../../shared/components/EntityModal';
import BasicForm from '../BasicForm';
import useEditModalManagement from './useEditModalManagement';
import useUniversitiesManagement from '../../hooks/useUniversitiesManagement';
import Departments from '../../../Departments';

const EditUniversityModal = () => {
  const { isOpened, handleClose, initialValues, university, isEditing } = useEditModalManagement();
  const { editUniversity } = useUniversitiesManagement();

  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Uniwersytet"
      onCreate={state => console.log(state)}
      onEdit={editUniversity}
      initialValues={initialValues}
      id={university?._id}
      isEditing={isEditing}
    >
      <BasicForm />
      <Departments />
    </EntityModal>
  );
};

export default EditUniversityModal;
