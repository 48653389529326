import { ModalsState, initialModalsState } from '../state/modals';
import { UsersModalsAction } from '../actions/modals';

const usersModalReducer = (
  state: ModalsState = initialModalsState,
  action: UsersModalsAction,
) => {
  switch (action.type) {
    case 'Users.Modal.OpenEditModal': {
      const { user } = action.payload;
      return {
        ...state,
        entity: user,
        isEditModalOpened: true,
      };
    }
    case 'Users.Modal.OpenCreateModal': {
      return {
        ...state,
        isCreateModalOpened: true,
      };
    }
    case 'Users.Modal.CloseCreateModal':
      return {
        ...state,
        isCreateModalOpened: false,
      };
    case 'Users.Modal.CloseEditModal':
      return {
        ...state,
        isEditModalOpened: false,
      };
    default:
      return state;
  }
};

export default usersModalReducer;
