import React, { FunctionComponent } from 'react';
import { Field, FieldProps } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from './styles.module.scss';
import MaterialInput from '../../MaterialInput';
import { Grid } from '@material-ui/core';

interface Props {
  name: string;
  type?: string;
  label?: string;
  dataTestId?: string;
  multiline?: boolean;
  className?: string;
}

const TextInput: FunctionComponent<Props> = ({
  name,
  label,
  type = 'text',
  dataTestId,
  multiline= false,
  className,
}) => (
  <Grid item={true} className={className}>
    <Field
      name={name}
    >
      {({ field, meta }: FieldProps) => (
        <FormControl error={Boolean(meta.error)} className={styles.root}>
          <MaterialInput
            dataTestId={dataTestId}
            name={name}
            label={label}
            type={type}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            multiline={multiline}
          />
          <FormHelperText
            id={`component-error-${name}`}
            className={meta.error ? '' :  styles.helperWithoutError}
          >
            {meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  </Grid>
);

export default TextInput;
