import React from 'react';
import useFieldOfStudyTypesLoading from './hooks/useFieldOfStudyTypesLoading';
import useFieldOfStudyTypesManagement from './hooks/useFieldOfStudyTypesManagement';
import Loader from '../../shared/components/Loader';
import List from '../../shared/components/List';
import FieldOfStudyTypeModal from './FieldOfStudyTypeModal';
import useFieldOfStudyTypeModalManagement from './hooks/useFieldOfStudyTypeModalManagement';

const FieldOfStudyTypes = () => {
  useFieldOfStudyTypesLoading();
  const { isLoading, fieldOfStudyTypes, deleteFieldOfStudyType } = useFieldOfStudyTypesManagement();
  const { handleOpen, handleEdit } = useFieldOfStudyTypeModalManagement();

  return (
    <Loader isLoading={isLoading}>
      <FieldOfStudyTypeModal />
      <List
        onAddButtonClick={handleOpen}
        onEdit={handleEdit}
        onRemove={deleteFieldOfStudyType}
        objects={fieldOfStudyTypes}
        properties={[{
          key: 'name',
          title: 'Nazwa',
        }]}
      />
    </Loader>
  );
};

export default FieldOfStudyTypes;
