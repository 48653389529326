import ThunkDispatch from '../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback } from 'react';
import LogInData from '../interfaces/LogInData';
import { authenticationActions } from '../actions';

function useAuthenticationManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const {
    isLoggedIn,
    isLoading,
    user,
  } = useSelector((state: AppState) => ({
    isLoggedIn: state.authentication.isLoggedIn,
    isLoading: state.authentication.isLoading,
    user: state.authentication.user,
  }));

  const logIn = useCallback(
    (logInData: LogInData) => {
      return dispatch(authenticationActions.logIn(logInData));
    },
    [dispatch],
  );

  const authenticate = useCallback(
    () => {
      return dispatch(authenticationActions.authenticate());
    },
    [dispatch],
  );

  const logOut = useCallback(
    () => {
      return dispatch(authenticationActions.logOut());
    },
    [dispatch],
  );

  return {
    isLoading,
    isLoggedIn,
    user,
    logIn,
    logOut,
    authenticate,
  };
}

export default useAuthenticationManagement;
