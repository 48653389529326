import React from 'react';
import EntityModal from '../../../shared/components/EntityModal';
import TextInput from '../../../shared/components/inputs/TextInput';
import useSubjectTypeModalManagement from '../hooks/useSubjectTypeModalManagement';
import useSubjectTypesManagement from '../hooks/useSubjectTypesManagement';

const AddSubjectTypeModal = () => {
  const { isOpened, handleClose, initialValues, isEditing, subjectType } = useSubjectTypeModalManagement();
  const { postSubjectType, editSubjectType } = useSubjectTypesManagement();
  return (
    <EntityModal
      onClose={handleClose}
      isOpened={isOpened}
      entityName="Typ przedmiotu"
      isEditing={isEditing}
      onCreate={postSubjectType}
      onEdit={editSubjectType}
      id={subjectType?._id}
      initialValues={initialValues}
    >
      <TextInput
        name="name"
        type="text"
        label="Nazwa"
      />
      <TextInput
        name="order"
        type="number"
        label="Kolejność"
      />
    </EntityModal>
  );
};

export default AddSubjectTypeModal;
