import Action from '../../../shared/types/Action';
import University from '../interfaces/University';

export type UniversitiesEditModalAction =
  | Action<
  'Universities.EditModal.Open', {
    university: University,
  }
  >
  | Action<
  'Universities.EditModal.Close'
  >;

export const universitiesEditModalAction = {
  open(university: University): UniversitiesEditModalAction {
    return {
      type: 'Universities.EditModal.Open',
      payload: {
        university,
      },
    };
  },
  close(): UniversitiesEditModalAction {
    return {
      type: 'Universities.EditModal.Close',
      payload: undefined,
    };
  },
};
