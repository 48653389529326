import useUniversitiesManagement from './useUniversitiesManagement';
import { useEffect } from 'react';

function useUniversitiesLoading() {
  const { fetchUniversities } = useUniversitiesManagement();
  useEffect(
    () => {
      fetchUniversities();
    },
    [fetchUniversities],
  );
}

export default useUniversitiesLoading;
