import React, { FunctionComponent } from 'react';
import { Bar, BarChart, BarChartProps, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = Pick<BarChartProps, 'data' | 'onClick'>;

const ReportsChart: FunctionComponent<Props> = ({
  data,
  onClick,
}) => {
  return (
    <ResponsiveContainer width="99%" height={300} debounce={10}>
      <BarChart width={0} data={data} onClick={onClick}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="total" fill="#8884d8" name="Wystąpienia" maxBarSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportsChart;
