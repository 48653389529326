import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { useCallback, useMemo } from 'react';
import { thresholdsModalActions } from '../actions/modal';
import Threshold from '../interfaces/Threshold';

function useThresholdsModalManagement() {
  const dispatch = useDispatch();
  const {
    isOpened,
    entity,
  } = useSelector((state: AppState) => ({
    isOpened: state.thresholds.modal.isOpened,
    entity: state.thresholds.modal.entity,
  }));
  const isEditing = Boolean(entity?._id);

  const handleOpen = useCallback(
    () => {
      dispatch(thresholdsModalActions.open());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (threshold?: Threshold) => (
      () => {
        dispatch(thresholdsModalActions.open(threshold));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      dispatch(thresholdsModalActions.close(),
    );
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => {
      return isEditing && entity ? {
        year: entity.year,
        value: entity.value,
      } : {
        year: '2020',
        value: 0,
      };
    },
    [entity, isEditing],
  );

  return {
    isOpened,
    isEditing,
    initialValues,
    handleOpen,
    handleClose,
    handleEdit,
    threshold: entity,
  };
}

export default useThresholdsModalManagement;
