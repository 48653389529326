import { UniversitiesAction } from '../actions';
import { UniversitiesState, initialState } from '../state';
import universitiesEditModalReducer from './editModal';
import { UniversitiesEditModalAction } from '../actions/editModal';

const universitiesReducer = (
  universitiesState: UniversitiesState = initialState,
  action: UniversitiesAction,
) => {
  const state = {
    ...universitiesState,
    editModal: universitiesEditModalReducer(universitiesState.editModal, action as UniversitiesEditModalAction),
  };
  switch (action.type) {
    case 'Universities.ReceiveEntities':
      const { universities } = action.payload;
      return {
        ...state,
        entities: universities,
      };
    case 'Universities.RemoveEntity': {
      const { id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities.splice(entityIndex, 1);
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Universities.ModifyEntity': {
      const { university, id } = action.payload;
      const entities = [
        ...state.entities,
      ];
      const entityIndex = entities.findIndex(entity => entity._id === id);
      if (entityIndex > -1) {
        entities[entityIndex] = university;
      }
      return {
        ...state,
        entities,
      };
    }
    case 'Universities.ReceiveEntity': {
      const { university } = action.payload;
      const entities = [
        ...state.entities,
      ];
      entities.push(university);
      return {
        ...state,
        entities,
      };
    }
    case 'Universities.OpenCreateModal': {
      return {
        ...state,
        createModal: {
          isOpened: true,
        },
      };
    }
    case 'Universities.CloseCreateModal': {
      return {
        ...state,
        createModal: {
          isOpened: false,
        },
      };
    }
    case 'Universities.StartLoading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'Universities.FinishLoading': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default universitiesReducer;
