import React from 'react';
import useStyles from './useStyles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import useUserMenuManagement from './useUserMenuManagement';
import Chip from '@material-ui/core/Chip';
import useAuthenticationManagement from '../../../../../modules/Authentication/hooks/useAuthenticationManagement';

const UserMenu = () => {
  const classes = useStyles();
  const {
    handleMenuOpen,
    handleMenuClose,
    handleLogOut,
    anchorElement,
    isOpened,
  } = useUserMenuManagement();
  const { user } = useAuthenticationManagement();
  return (
    <div className={classes.wrapper}>
      <Chip
        avatar={<AccountCircle/>}
        label={user?.name}
        onClick={handleMenuOpen}
        color="default"
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpened}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogOut}>Log out</MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
