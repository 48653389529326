import Calculation from '../interfaces/Calculation';

export interface ModalState {
  isOpened: boolean;
  entity?: Calculation;
}

export const initialModalState: ModalState = {
  isOpened: false,
};
