import React from 'react';
import ReactDOM from 'react-dom';
import App from './shared/components/App';
import { Provider } from 'react-redux';
import store from './store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

ReactDOM.render(
  (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale="pl">
        <App />
      </MuiPickersUtilsProvider>
    </Provider>
  ),
  document.getElementById('root'),
);
