import ThunkDispatch from '../../../../shared/types/ThunkDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers';
import { useCallback, useMemo } from 'react';
import { universitiesEditModalAction } from '../../actions/editModal';
import University from '../../interfaces/University';
import { departmentsListActions } from '../../../Departments/actions/list';

function useEditModalManagement() {
  const dispatch: ThunkDispatch = useDispatch();
  const { isOpened, entity } = useSelector((state: AppState) => ({
    isOpened: state.universities.editModal.isOpened,
    entity: state.universities.editModal.entity,
  }));

  const handleOpen = useCallback(
    (university: University) => (
      () => {
        dispatch(departmentsListActions.fetchDepartments(university._id));
        return dispatch(universitiesEditModalAction.open(university));
      }
    ),
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      return dispatch(universitiesEditModalAction.close());
    },
    [dispatch],
  );

  const initialValues = useMemo(
    () => ({
      name: entity?.name,
      type: entity?.type,
      city: entity?.city,
      isPrivate: entity?.isPrivate || false,
      description: entity?.description,
      photoUrl: entity?.photoUrl,
      logoUrl: entity?.logoUrl,
    }),
    [entity],
  );

  return {
    isOpened,
    handleOpen,
    handleClose,
    initialValues,
    university: entity,
    isEditing: Boolean(entity?._id),
  };

}

export default useEditModalManagement;
