import React, { FunctionComponent } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { SvgIconProps } from '@material-ui/core';
import styles from './styles.module.scss';
import useCurrentLocation from './useCurrentLocation';

interface Props {
  name: string;
  path: string;
  Icon: (props: SvgIconProps) => JSX.Element;
}

const Item: FunctionComponent<Props> = ({
  name,
  path,
  Icon,
}) => {
  const {
    isCurrentLocation,
  } = useCurrentLocation(path);
  return (
    <Link to={path} className={styles.link}>
      <ListItem button={true} selected={isCurrentLocation}>
        <ListItemIcon><Icon/></ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

export default Item;
